import { createQuery } from '@tanstack/solid-query'
import { For, type JSX, Match, Show, Switch } from 'solid-js'

import { useAppStoresCtx } from '../../appStores'
import { EventCard, SkeletonEventCard } from '../../components/EventCard'
import { ScrollSection } from '../../components/ScrollSection'
import {
  type DateString,
  type Latitude,
  type Longitude,
  type ThemeName,
} from '../../db/schema.constants'
import { useI18n } from '../../i18n'
import { range } from '../../utils'
import { DEFAULT_PAGE_SIZE, type HomeOptFilter } from './home.constants'
import { type GetPoisResponseItem, getRecommendedPois } from './home.telefunc'

export function RecommendedBanner(props: {
  position: [Longitude, Latitude] | undefined | null
  date: [DateString, DateString] | [DateString] | null
  distance: number
  opts: HomeOptFilter[]
}): JSX.Element {
  const t = useI18n()

  const { userInfo } = useAppStoresCtx()

  const promotionQuery = createQuery(() => {
    const pos = props.position
    const distanceKm = props.distance
    const filterDate = props.date
    const filtersOpts = props.opts
    const offset = 0
    const pageSize = DEFAULT_PAGE_SIZE

    return {
      enabled: pos != null,
      queryKey: [
        'getRecommendedPois',
        pos,
        distanceKm,
        filtersOpts,
        filterDate,
        offset,
        pageSize,
      ],
      queryFn: async (): Promise<
        Array<GetPoisResponseItem & { theme: ThemeName | null }>
      > => {
        if (!pos) {
          return []
        }
        const rslt = await getRecommendedPois({
          pos,
          distanceKm,
          filtersOpts,
          filterDate,
          offset,
          pageSize,
        })
        return rslt
      },
    }
  })

  return (
    <>
      <Show
        when={!(promotionQuery.isSuccess && promotionQuery.data.length <= 0)}
      >
        <div class="flex items-baseline justify-between pt-10">
          <h2 class="text-balance text-grey-50 text-4xl">
            {t('home.recommendation_title', {
              firstName: userInfo.data?.firstName ?? '',
            })}
          </h2>
        </div>
        <p class="text-balance pb-8 text-grey-50 text-sm">
          {t('home.recommendation_subtitle')}
        </p>
      </Show>
      <ScrollSection>
        <Switch
          fallback={
            <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
              {() => <SkeletonEventCard size="lg" />}
            </For>
          }
        >
          <Match when={promotionQuery.isError}>
            <p class="text-coral">
              {promotionQuery.error?.message ?? 'unknown-error'}
            </p>
          </Match>
          <Match when={promotionQuery.isSuccess}>
            <For each={promotionQuery.data}>
              {(item, index) => (
                <EventCard
                  themeName={item.theme ?? 'concerts'}
                  slugFr={item.slugFr}
                  poiId={item.id}
                  title={item.label}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  poiTime={item.poiTime}
                  distanceKm={item.distance / 1000}
                  locality={item.locality}
                  mediaFileId={item.mainMediaFileId}
                  mainMediaFileCredits={item.mainMediaFileCredits}
                  mainMediaFileLicense={item.mainMediaFileLicense}
                  randomIdx={index()}
                  size="lg"
                />
              )}
            </For>
          </Match>
        </Switch>
      </ScrollSection>
    </>
  )
}
