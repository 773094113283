import type { ClassValue } from 'clsx'
import clsx from 'clsx'
import { isValid, parse } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import { type ObjectEntry } from 'type-fest/source/entry'

import { type DateString, type TimeString } from '#db/schema.constants'

export function cn(...classLists: ClassValue[]): string {
  return twMerge(clsx(classLists))
}

export function exhaustiveCheck(param: never): void {
  console.error(`exahustive check, cause: ${JSON.stringify(param)}`)
}

/**
 * Same as Object.keys() but with better typing
 * */
export function keys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>
}

/**
 * Same as Object.entries() but with better typing
 * */
export function entries<T extends object>(obj: T): Array<ObjectEntry<T>> {
  return Object.entries(obj) as Array<ObjectEntry<T>>
}

/**
 * Same as Object.fromEntries() but with better typing
 * */
export function fromEntries<K extends string, T>(
  entries: Iterable<readonly [K, T]>,
): Record<K, T> {
  return Object.fromEntries(entries) as unknown as Record<K, T>
}

/**
 * Map an object by its entries (tuples key/value)
 * */
export function mapByEntries<T extends object, MappedK extends string, MappedV>(
  obj: T,
  mapper: (entry: ObjectEntry<T>) => [MappedK, MappedV],
): Record<MappedK, MappedV> {
  return fromEntries(entries(obj).map(mapper))
}

/**
 * Determines whether a given color is dark or light.
 * @param color The color in hex format.
 */
export function isColorDark(
  _color: string,
): 'light' | 'dark' | 'invalid-format' {
  if (!/#?\d{6}/.test(_color)) {
    return 'invalid-format'
  }
  const hex = _color.startsWith('#') ? _color.substring(1, 7) : _color
  const r = parseInt(hex.substring(0, 2), 16) // hexToR
  const g = parseInt(hex.substring(2, 4), 16) // hexToG
  const b = parseInt(hex.substring(4, 6), 16) // hexToB
  // luminance https://www.w3.org/TR/AERT/#color-contrast
  const l = r * 0.299 + g * 0.587 + b * 0.114
  return l > 186 ? 'light' : 'dark'
}

/**
 * Returns a promise that resolves after `waitMs` milliseconds
 */
export async function wait(waitMs: number): Promise<void> {
  await new Promise<void>((resolve) => setTimeout(resolve, waitMs))
}

/**
 * Asynchronously yields chunks of the given array.
 *
 * @example
 * for await (const chunk of asyncChunk([a, b, c, d, e], 2)) {
 *  console.log(chunk); // Outputs: [a, b] then [c, d] then [e]
 * }
 *
 * @param arr The array to chunk.
 * @param size The size of each chunk.
 * @returns An asynchronous generator yielding chunks of the array.
 */
export function* asyncChunk<T>(arr: T[], size: number): Generator<T[]> {
  for (let i = 0; i < arr.length; i += size) {
    yield arr.slice(i, i + size)
  }
}

/**
 * Returns a string with the first letter capitalized
 */
export function capitalizeFirstLetter<S extends string>(str: S): Capitalize<S> {
  return (str.charAt(0).toLocaleUpperCase() + str.slice(1)) as Capitalize<S>
}

export function slugify(str: string): string {
  return str
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .split(/\W+/)
    .filter(Boolean)
    .join('-')
}

export function clamp(n: number, min: number, max: number): number {
  return Math.min(Math.max(n, min), max)
}

export function shuffle<T>(array: T[]): T[] {
  return array.slice().sort(() => Math.random() - 0.5)
}

export function sample<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]
}

export function range(start: number, end: number): number[] {
  return [...Array(end - start + 1).keys()].map((i) => i + start)
}

export function assertDateString(str: string): asserts str is DateString {
  const ret = isValid(parse(str, 'yyyy-MM-dd', new Date()))
  if (!ret) throw new Error(`Invalid date string: ${str}`)
}

export function assertTimeString(str: string): asserts str is TimeString {
  const ret =
    isValid(parse(str, 'HH:mm', new Date())) ||
    isValid(parse(str, 'HH:mm:ss', new Date()))
  if (!ret) throw new Error(`Invalid time string: ${str}`)
}

export function isInArray<T extends string[] | readonly string[]>(
  value: string | undefined,
  array: T,
): value is T[number] {
  if (!value) return false
  return array.includes(value)
}

export function addStyleToBodyForModal(): void {
  if (document.body.scrollHeight > window.innerHeight) {
    const scrollbarWidth =
      (document.getElementById('bg-city-map')?.scrollWidth ??
        document.body.offsetWidth) - document.body.offsetWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }
  document.body.classList.add('overflow-hidden')
}

export function removeStyleFromBodyForModal(): void {
  document.body.style.paddingRight = ''
  document.body.classList.remove('overflow-hidden')
}
