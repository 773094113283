import { A, useParams } from '@solidjs/router'
import { createQuery } from '@tanstack/solid-query'
import { For, type JSX, Match, Show, Switch } from 'solid-js'

import { useAppStoresCtx } from '../../appStores'
import { EventCard, SkeletonEventCard } from '../../components/EventCard'
import { RightChevronIcon } from '../../components/Icon'
import { ScrollSection } from '../../components/ScrollSection'
import {
  type DateString,
  type Latitude,
  type Longitude,
  type ThemeName,
} from '../../db/schema.constants'
import { useI18n } from '../../i18n'
import { range } from '../../utils'
import { DEFAULT_PAGE_SIZE, type HomeOptFilter } from './home.constants'
import { type GetPoisResponseItem, getPromotedPois } from './home.telefunc'

export function PromotionBanner(props: {
  position: [Longitude, Latitude] | null | undefined
  date: [DateString, DateString] | [DateString] | null
  distance: number
  opts: HomeOptFilter[]
  theme: ThemeName | null
}): JSX.Element {
  const t = useI18n()

  const { isSmallScreen } = useAppStoresCtx()

  const params = useParams<{ townSlug?: string }>()

  const promotionQuery = createQuery(() => {
    const pos = props.position
    const dist = props.distance
    const date = props.date
    const opts = props.opts
    const offset = 0
    const pageSize = DEFAULT_PAGE_SIZE
    const theme = props.theme

    return {
      enabled: pos != null,
      queryKey: [
        'getPromotedPois',
        pos,
        dist,
        opts,
        date,
        theme,
        offset,
        pageSize,
      ],
      queryFn: async (): Promise<
        Array<GetPoisResponseItem & { theme: ThemeName | null }>
      > => {
        if (!pos) {
          return []
        }
        return await getPromotedPois({
          pos,
          distanceKm: dist,
          themeName: props.theme,
          filtersOpts: opts,
          filterDate: date,
          offset,
          pageSize,
        })
      },
    }
  })

  return (
    <Show when={!(promotionQuery.isSuccess && promotionQuery.data.length <= 0)}>
      <div class="flex items-center justify-between pt-10">
        <h2 class="text-balance text-grey-50 text-4xl">
          <Show when={isSmallScreen()} fallback={t('home.promotion_title')}>
            <A
              href={`${
                params.townSlug != null ? `/ville/${params.townSlug}` : ''
              }/promotions?${location.search}`}
              class="hover:underline"
            >
              {t('home.promotion_title')}
            </A>
          </Show>
        </h2>

        <A
          href={`${
            params.townSlug != null ? `/ville/${params.townSlug}` : ''
          }/promotions?${location.search}`}
          class="group flex items-center gap-2"
        >
          <p class="hidden font-semibold group-hover:underline sm:inline-block">
            {t('home.see_all')}
          </p>
          <RightChevronIcon class="inline-block size-6 rounded-full transition-opacity duration-150 group-hover:opacity-100 sm:opacity-0" />
        </A>
      </div>
      <p class="text-balance pb-8 text-grey-50 text-sm">
        {t('home.promotion_subtitle')}
      </p>
      <ScrollSection>
        <Switch
          fallback={
            <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
              {() => <SkeletonEventCard size="lg" />}
            </For>
          }
        >
          <Match when={promotionQuery.isError}>
            <p class="text-coral">
              {promotionQuery.error?.message ?? 'unknown-error'}
            </p>
          </Match>
          <Match when={promotionQuery.isSuccess}>
            <For each={promotionQuery.data}>
              {(item, index) => (
                <EventCard
                  themeName={props.theme ?? item.theme ?? 'concerts'}
                  slugFr={item.slugFr}
                  poiId={item.id}
                  title={item.label}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  poiTime={item.poiTime}
                  distanceKm={item.distance / 1000}
                  locality={item.locality}
                  mediaFileId={item.mainMediaFileId}
                  mainMediaFileCredits={item.mainMediaFileCredits}
                  mainMediaFileLicense={item.mainMediaFileLicense}
                  size="lg"
                  randomIdx={index()}
                />
              )}
            </For>
          </Match>
        </Switch>
      </ScrollSection>
    </Show>
  )
}
