import { useNavigate } from '@solidjs/router'
import { createMutation } from '@tanstack/solid-query'
import { createMemo, type JSX, untrack } from 'solid-js'
import toast from 'solid-toast'

import { useAppStoresCtx } from '../appStores'
import { type PoiId } from '../db/schema.constants'
import { useI18n } from '../i18n'
import { InfoCircleIcon } from './Icon'

export function FavoriteButton(props: {
  poiId: PoiId
  children: (
    handleClick: () => void,
    isFavorite: boolean,
    loading: boolean,
  ) => JSX.Element
}): JSX.Element {
  const navigator = useNavigate()
  const t = useI18n()
  const { favoritesIds, toggleFavorite, userInfo } = useAppStoresCtx()

  const togglingMutation = createMutation(() => ({
    mutationFn: async (): Promise<void> => toggleFavorite(props.poiId),
  }))

  const loading = createMemo(
    () => togglingMutation.isPending || favoritesIds.isLoading,
  )

  const isFavorite = createMemo(
    () => favoritesIds.data?.includes(props.poiId) ?? false,
  )

  return (
    <>
      {props.children(
        () => {
          const user = untrack(() => userInfo.data)
          if (!user) {
            toast.error(t('toasts.on_abort'), {
              duration: 3000,
              icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
            })
            navigator('/auth/login')
            return
          }
          togglingMutation.mutate()
        },
        isFavorite(),
        loading(),
      )}
    </>
  )
}
