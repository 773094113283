import { Meta, Title } from '@solidjs/meta'
import { A, useLocation, useParams } from '@solidjs/router'
import { createQuery } from '@tanstack/solid-query'
import { For, type JSX, mapArray, Match, Show, Switch } from 'solid-js'

import { useAppStoresCtx } from '#/appStores'
import SeparatorCard, {
  EventCard,
  SkeletonEventCard,
} from '#/components/EventCard'
import { RightChevronIcon } from '#/components/Icon'
import { ScrollSection } from '#/components/ScrollSection'
import { useI18n } from '#/i18n'
import { THEMES } from '#db/schema.constants'

import { range } from '../../utils'
import { DEFAULT_PAGE_SIZE } from './home.constants'
import {
  getPoisByAddressDistance,
  type GetPoisResponseItem,
} from './home.telefunc'
import { PromotionBanner } from './PromotionBanner'
import { RecommendedBanner } from './RecommendedBanner'
import { SearchNav } from './SearchNav'

export default function Home(): JSX.Element {
  const t = useI18n()
  const location = useLocation()

  const params = useParams<{ townSlug?: string }>()

  const {
    position,
    distance,
    userInfo,
    parsedDateRange,
    optsArr,
    isSmallScreen,
  } = useAppStoresCtx()

  const themesRslt = mapArray(
    () => THEMES,
    (theme) => {
      return {
        label: t(`themes.${theme}.label`),
        subLabel: t(`themes.${theme}.subLabel`),
        themeName: theme,
        query: createQuery(() => {
          const pos = position()?.coords
          const distanceKm = distance()
          const filterDate = parsedDateRange()
          const filtersOpts = optsArr()
          const themeName = theme

          const excludePoiId = null
          const offset = 0
          const pageSize = DEFAULT_PAGE_SIZE
          const auto_extend = true

          return {
            enabled: pos != null,
            queryKey: [
              'getPoisByAddressDistance',
              pos,
              distanceKm,
              themeName,
              filtersOpts,
              filterDate,
              excludePoiId,
              offset,
              pageSize,
              auto_extend,
            ],
            queryFn: async (): Promise<{
              length: number
              items: GetPoisResponseItem[]
              extra_items: GetPoisResponseItem[]
            }> => {
              if (!pos) {
                return {
                  length: 0,
                  items: [],
                  extra_items: [],
                }
              }
              const items = await getPoisByAddressDistance({
                pos,
                distanceKm,
                themeName,
                filtersOpts,
                filterDate,
                excludePoiId,
                offset,
                pageSize,
                auto_extend,
              })
              return {
                length: items.length,
                items: items.filter(
                  (item) => item.distance / 1000 <= distanceKm,
                ),
                extra_items: items.filter(
                  (item) => item.distance / 1000 > distanceKm,
                ),
              }
            },
          }
        }),
      }
    },
  )

  return (
    <>
      <Title>
        {t('home.meta_title', {
          city: position()?.name ?? '...',
        })}
        - Lokaly
      </Title>
      <Meta name="description" content={t('home.meta_description')} />

      <SearchNav titlePrefix={t('home.title')} />

      <PromotionBanner
        position={position()?.coords}
        distance={distance()}
        date={parsedDateRange()}
        opts={optsArr()}
        theme={null}
      />

      <Show when={userInfo.data}>
        <RecommendedBanner
          position={position()?.coords}
          distance={distance()}
          date={parsedDateRange()}
          opts={optsArr()}
        />
      </Show>

      <For each={themesRslt()}>
        {(theme, indexTheme) => (
          <>
            <Show
              when={!(theme.query.isSuccess && theme.query.data.length === 0)}
            >
              <div class="flex items-center justify-between pt-10">
                <h2 class="text-balance text-grey-50 text-4xl">
                  <Show when={isSmallScreen()} fallback={theme.label}>
                    <A
                      href={`${
                        params.townSlug != null
                          ? `/ville/${params.townSlug}`
                          : ''
                      }/category/${theme.themeName}${location.search}`}
                      class="hover:underline"
                      itemid=""
                    >
                      {theme.label}
                    </A>
                  </Show>
                </h2>

                <A
                  href={`${
                    params.townSlug != null ? `/ville/${params.townSlug}` : ''
                  }/category/${theme.themeName}${location.search}`}
                  class="group flex items-center gap-2"
                >
                  <p class="hidden font-semibold group-hover:underline sm:inline-block">
                    {t('home.see_all')}
                  </p>
                  <RightChevronIcon class="inline-block size-6 rounded-full transition-opacity duration-150 group-hover:opacity-100 sm:opacity-0" />
                </A>
              </div>
              <p class="text-balance pb-8 text-grey-50 text-sm">
                {theme.subLabel}
              </p>
              <ScrollSection>
                <Switch
                  fallback={
                    <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
                      {() => <SkeletonEventCard />}
                    </For>
                  }
                >
                  <Match when={theme.query.isError}>
                    <p class="text-coral">
                      {theme.query.error?.message ?? 'unknown-error'}
                    </p>
                  </Match>
                  <Match when={theme.query.isSuccess}>
                    <For each={theme.query.data?.items}>
                      {(item, indexItem) => (
                        <EventCard
                          themeName={theme.themeName}
                          slugFr={item.slugFr}
                          poiId={item.id}
                          title={item.label}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          poiTime={item.poiTime}
                          distanceKm={item.distance / 1000}
                          locality={item.locality}
                          mediaFileId={item.mainMediaFileId}
                          mainMediaFileCredits={item.mainMediaFileCredits}
                          mainMediaFileLicense={item.mainMediaFileLicense}
                          randomIdx={indexTheme() + indexItem()}
                        />
                      )}
                    </For>
                    <Show when={theme.query.data?.extra_items.length}>
                      <SeparatorCard msg={t('home.more_results')} />
                    </Show>
                    <For each={theme.query.data?.extra_items}>
                      {(item, indexItem) => (
                        <EventCard
                          themeName={theme.themeName}
                          slugFr={item.slugFr}
                          poiId={item.id}
                          title={item.label}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          poiTime={item.poiTime}
                          distanceKm={item.distance / 1000}
                          locality={item.locality}
                          mediaFileId={item.mainMediaFileId}
                          mainMediaFileCredits={item.mainMediaFileCredits}
                          mainMediaFileLicense={item.mainMediaFileLicense}
                          randomIdx={
                            indexTheme() +
                            (theme.query.data?.items.length ?? 0) +
                            1 +
                            indexItem()
                          }
                        />
                      )}
                    </For>
                  </Match>
                </Switch>
              </ScrollSection>
            </Show>
          </>
        )}
      </For>
    </>
  )
}
