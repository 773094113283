import { eq, sql } from 'drizzle-orm'
import { Abort, getContext } from 'telefunc'

import { db, schema } from './db'
import { jsonTextPlaceholder } from './db/jsonb-field'
import {
  type FeatureGroupName,
  type FeatureName,
  type Latitude,
  type Longitude,
  type ThemeName,
} from './db/schema.constants'
import { MAX_DISTANCE_KM } from './pages/home/home.constants'
import { clamp, fromEntries } from './utils'

const preparedGetAllFeatures = db.query.feature
  .findMany({
    columns: {
      name: true,
      featureGroupName: true,
    },
    extras(f) {
      return {
        label: jsonTextPlaceholder(f.label, 'label'),
      }
    },
  })
  .prepare('get_all_features')

export async function getEntitiesToCache(): Promise<{
  features: Record<
    FeatureName,
    { label: string; featureGroupName: FeatureGroupName | null }
  >
}> {
  const { lang } = getContext()

  const features = await preparedGetAllFeatures.execute({ lang })

  return {
    features: fromEntries(
      features.map(({ name, label, featureGroupName }) => [
        name,
        { label: label ?? name, featureGroupName },
      ]),
    ),
  }
}

const preparedGetFavThemes = db.query.usersToFavThemes
  .findMany({
    columns: {
      themeName: true,
    },
    where(f) {
      return eq(f.userId, sql.placeholder('userId'))
    },
  })
  .prepare('get_fav_themes')

export async function getFavoritesThemeNames(): Promise<ThemeName[]> {
  const { userId, logger } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    const result = await preparedGetFavThemes.execute({
      userId,
    })

    return result.map(({ themeName }) => themeName)
  } catch (err) {
    logger.error(err, 'getFavoritesThemeNames error')
    throw new Error('unexpected-error')
  }
}

export async function setPositionOnUserProfile(
  name: string,
  // do not use tagged type because of telefunc shields
  coordinates: [number, number],
  distance?: number,
): Promise<void> {
  const { userId, logger } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    await db
      .update(schema.userProfile)
      .set({
        position: coordinates as [Longitude, Latitude],
        locality: name,
        ...(distance ? { distance: clamp(distance, 0, MAX_DISTANCE_KM) } : {}),
      })
      .where(eq(schema.userProfile.supertokenId, userId))
      .execute()
  } catch (err) {
    logger.error(err, 'setPositionOnUserProfile error')
    throw new Error('unexpected-error')
  }
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function warmUp(): Promise<void> {
  const { logger } = getContext()
  logger.info('warming up appStores.telefunc...')
}
