if (import.meta.env.DEV) {
  await import('solid-devtools')
  const overlay = await import('@solid-devtools/overlay')
  overlay.attachDevtoolsOverlay()
}
import { render } from 'solid-js/web'
import SuperTokens from 'supertokens-web-js'
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import EmailVerification from 'supertokens-web-js/recipe/emailverification'
import Session from 'supertokens-web-js/recipe/session'
import ThirdParty from 'supertokens-web-js/recipe/thirdparty'
import { config } from 'telefunc/client'

import App from './App'

SuperTokens.init({
  appInfo: {
    apiDomain: window.location.origin,
    apiBasePath: '/auth-api',
    appName: 'lokaly',
  },
  recipeList: [
    Session.init(),
    EmailVerification.init(),
    EmailPassword.init(),
    ThirdParty.init(),
  ],
})

let deviceId = localStorage.getItem('deviceId')
if (!deviceId) {
  deviceId = window.crypto.randomUUID()
  localStorage.setItem('deviceId', deviceId)
}
config.httpHeaders = {
  'x-lokaly-device-id': deviceId,
}

render(() => <App />, document.getElementById('root') ?? document.body)
