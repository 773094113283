import { isSameDay } from 'date-fns'
import {
  batch,
  createMemo,
  createSignal,
  For,
  type JSX,
  Show,
  untrack,
} from 'solid-js'
import { createStore } from 'solid-js/store'

import {
  type DateString,
  type Latitude,
  type Longitude,
} from '#db/schema.constants'

import { useAppStoresCtx } from '../../appStores'
import { Button } from '../../components/Button'
import {
  DATE_PRESETS,
  DatePicker,
  type DatePresetName,
} from '../../components/DatePicker'
import { FeedbackFormButton } from '../../components/FeedbackFormButton'
import {
  CrossCloseIcon,
  /* MapIcon, */
  SlidersIcon,
} from '../../components/Icon'
import { MobilePortal } from '../../components/RootLayout'
import { useI18n } from '../../i18n'
import {
  addStyleToBodyForModal,
  removeStyleFromBodyForModal,
} from '../../utils'
import { type OptionItem } from './CitySelector'
import { DistanceSlider } from './DistanceSlider'
import {
  DEFAULT_DISTANCE_KM,
  HOME_OPT_FILTERS,
  type HomeOptFilter,
  STEPS_KM,
} from './home.constants'
import { LocationCombobox } from './LocationComboBox'

export function MobileSearchMenu(): JSX.Element {
  const { distance, position, parsedDateRange, optsArr, setFilters } =
    useAppStoresCtx()

  const [mobileStore, setMobileStore] = createStore<{
    distanceSteps: number
    inputValue: OptionItem
    dateRange: [DateString, DateString] | [DateString] | null
    optFilters: HomeOptFilter[]
    open: boolean
  }>({
    distanceSteps: 3,
    open: false,
    dateRange: null,
    optFilters: [],
    inputValue: {
      fullName: '',
      id: '',
      name: '',
      position: [0 as Longitude, 47 as Latitude],
    },
  })

  const t = useI18n()
  let inputRef: HTMLInputElement | undefined
  let divRef: HTMLDivElement | undefined

  const selectedStoreDatePresets = createMemo<DatePresetName | null>(() => {
    const [day1, day2] = mobileStore.dateRange ?? []
    const presetName =
      day1 != null && day2 != null
        ? (DATE_PRESETS.find(
            (p) =>
              isSameDay(p.range[0].toString(), day1) &&
              isSameDay(p.range[1].toString(), day2),
          )?.name ?? null)
        : null
    return presetName
  })

  const [openDatePicker, setOpenDatePicker] = createSignal(
    untrack(
      () => selectedStoreDatePresets() == null && mobileStore.dateRange != null,
    ),
  )

  return (
    <MobilePortal>
      <Show when={mobileStore.open}>
        <div
          ref={divRef}
          class="order-1 mx-2.5 mt-2.5 rounded-3xl bg-grey-50 py-5 text-medium-blue sm:hidden"
        >
          <div class="flex max-h-[calc(100dvh-12rem)] w-full flex-col gap-4 overflow-y-auto overflow-x-visible px-5 py-0.5">
            <div class="flex flex-col gap-3">
              <p>
                {t('city_selector.title')}
                <span class="font-semibold">{mobileStore.inputValue.name}</span>
              </p>

              <LocationCombobox
                value={mobileStore.inputValue}
                onChange={(val) => {
                  setMobileStore('inputValue', val)
                }}
                mount={divRef}
                ref={inputRef}
              />

              <DistanceSlider
                value={mobileStore.distanceSteps}
                onChange={(val) => {
                  setMobileStore('distanceSteps', val)
                }}
              />
            </div>
            <div class="flex flex-col gap-3">
              <p>{t('city_selector.when_label')}</p>
              <div class="flex flex-wrap gap-4">
                <For each={DATE_PRESETS}>
                  {(p) => {
                    const isSelected = createMemo(
                      () => p.name === selectedStoreDatePresets(),
                    )
                    return (
                      <Button
                        variant={isSelected() ? 'solidInvert' : 'outlineInvert'}
                        onClick={() => {
                          batch(() => {
                            setOpenDatePicker(false)
                            setMobileStore(
                              'dateRange',
                              isSelected()
                                ? null
                                : (p.range.map(
                                    (d) => `${d.year}-${d.month}-${d.day}`,
                                  ) as [DateString, DateString]),
                            )
                          })
                        }}
                      >
                        {t(`date_picker.${p.name}`)}
                      </Button>
                    )
                  }}
                </For>
                <Button
                  variant={openDatePicker() ? 'solidInvert' : 'outlineInvert'}
                  onclick={() => {
                    batch(() => {
                      if (selectedStoreDatePresets() != null) {
                        setMobileStore('dateRange', null)
                      }
                      setOpenDatePicker((prev) => !prev)
                    })
                  }}
                >
                  {t('date_picker.custom')}
                </Button>
              </div>
              <Show when={openDatePicker()}>
                <DatePicker
                  hidePreset
                  onChange={(val) => {
                    setMobileStore('dateRange', val)
                  }}
                  value={mobileStore.dateRange}
                />
              </Show>
            </div>
            <div class="flex flex-col gap-3">
              <p>{t('city_selector.options_label')}</p>
              <div class="flex flex-wrap gap-4">
                <For each={HOME_OPT_FILTERS}>
                  {(opt) => (
                    <Button
                      variant={
                        mobileStore.optFilters.includes(opt)
                          ? 'solidInvert'
                          : 'outlineInvert'
                      }
                      onClick={() => {
                        const _opts = untrack(() => mobileStore.optFilters)
                        const opts = _opts.includes(opt)
                          ? _opts.filter((o) => o !== opt)
                          : [..._opts, opt]
                        setMobileStore('optFilters', opts)
                      }}
                    >
                      {t(`home.filters.${opt}`)}
                    </Button>
                  )}
                </For>
              </div>
            </div>
          </div>
        </div>
      </Show>
      <div class="pointer-events-none flex justify-between p-2.5 sm:p-6">
        {/* <button
          class="pointer-events-auto flex size-16 items-center justify-center rounded-full border-[3px] border-grey-50 bg-medium-blue"
          aria-label={t('accessibility.map_button')}
        >
          <MapIcon class="size-6" />
        </button> */}
        <FeedbackFormButton />

        <button
          onClick={() => {
            const [_open, _step, _input, _dist, _pos, _opts, _date] = untrack(
              () => [
                mobileStore.open,
                mobileStore.distanceSteps,
                mobileStore.inputValue,
                distance(),
                position(),
                optsArr(),
                parsedDateRange(),
              ],
            )
            if (_open) {
              // close modal
              batch(() => {
                setFilters({
                  dateRange: mobileStore.dateRange,
                  distance: STEPS_KM[_step]?.val ?? DEFAULT_DISTANCE_KM,
                  position: {
                    coords: _input.position,
                    name: _input.name,
                  },
                  opts: mobileStore.optFilters,
                })
                removeStyleFromBodyForModal()
                setMobileStore('open', false)
              })
            } else {
              // open modal
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              const initialStep = STEPS_KM.findIndex(
                (step) => step.val === _dist,
              )
              addStyleToBodyForModal()
              inputRef?.focus()
              setMobileStore({
                distanceSteps: initialStep > 0 ? initialStep : 3,
                inputValue: {
                  fullName: _pos?.name ?? '',
                  id: '',
                  name: _pos?.name ?? '',
                  position: _pos?.coords ?? [0 as Longitude, 47 as Latitude],
                },
                optFilters: _opts,
                dateRange: _date,
                open: true,
              })
            }
          }}
          class="pointer-events-auto flex size-16 items-center justify-center rounded-full border-[3px] border-grey-50 sm:hidden"
          classList={{
            'bg-grey-50 text-medium-blue': mobileStore.open,
            'bg-medium-blue text-grey-50': !mobileStore.open,
          }}
          aria-label={t('accessibility.filters_button')}
        >
          <Show
            when={mobileStore.open}
            fallback={<SlidersIcon class="size-6" />}
          >
            <CrossCloseIcon class="size-6" />
          </Show>
        </button>
      </div>
    </MobilePortal>
  )
}
