import type {
  DropdownMenuCheckboxItemProps,
  DropdownMenuContentProps,
  DropdownMenuRadioItemProps,
  DropdownMenuRootProps,
} from '@kobalte/core/dropdown-menu'
import { DropdownMenu as DropdownMenuPrimitive } from '@kobalte/core/dropdown-menu'
import type { PolymorphicProps } from '@kobalte/core/polymorphic'
import { cva } from 'class-variance-authority'
import type { JSX, ParentProps, ValidComponent } from 'solid-js'
import { mergeProps, Show, splitProps } from 'solid-js'

import { cn } from '#/utils'

import { CheckIcon, CrossCloseIcon } from './Icon'

export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

export function DropdownMenu(props: DropdownMenuRootProps): JSX.Element {
  const merge = mergeProps<DropdownMenuRootProps[]>({ gutter: 4 }, props)

  return <DropdownMenuPrimitive {...merge} />
}

type DropdownButtonTriggerProps = ParentProps & {
  class?: string
  active: boolean
  open: boolean
  onClickDelete?: () => void
}

const baseStyles =
  'rounded-lg flex items-center gap-2 px-4 py-2 text-xs font-semibold uppercase transition-[color,background-color,box-shadow]'

const buttonTriggerVariants = cva(baseStyles, {
  variants: {
    variant: {
      default: 'bg-dark-50 hover:bg-dark-80 text-grey-200',
      opened: 'bg-dark-80 text-grey-200 outline outline-2 outline-grey-200',
      active: 'bg-grey-50 text-medium-blue',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const iconVariants = cva('h-6 w-6 transition-all', {
  variants: {
    variant: {
      default: 'text-light-20 hover:text-light-30',
      active: 'text-grey-700 hover:text-grey-800',
    },
  },
})

export function DropdownButtonTrigger(
  props: DropdownButtonTriggerProps,
): JSX.Element {
  const [local, rest] = splitProps(props, [
    'children',
    'active',
    'open',
    'class',
    'onClickDelete',
  ])

  return (
    <div
      {...rest}
      class={cn(
        buttonTriggerVariants({
          variant: local.open ? 'opened' : local.active ? 'active' : 'default',
        }),
        local.class,
      )}
    >
      {local.children}
      <Show when={props.active && local.onClickDelete != null}>
        <button
          class="pointer-events-auto"
          onPointerDown={(e) => {
            e.stopPropagation()
            local.onClickDelete?.()
          }}
        >
          <CrossCloseIcon
            class={iconVariants({
              variant: local.open ? 'default' : 'active',
            })}
          />
        </button>
      </Show>
    </div>
  )
}

type dropdownMenuContentProps<T extends ValidComponent = 'div'> =
  DropdownMenuContentProps<T> & {
    class?: string
  }

export function DropdownMenuContent<T extends ValidComponent = 'div'>(
  props: PolymorphicProps<T, dropdownMenuContentProps<T>>,
): JSX.Element {
  const [local, rest] = splitProps(props as dropdownMenuContentProps, ['class'])

  const baseStyles =
    'min-w-32 z-50 overflow-hidden rounded-md border bg-grey-50 px-1 py-4 text-medium-blue shadow-md transition-shadow data-[expanded]:animate-in data-[closed]:animate-out data-[closed]:fade-out-0 data-[expanded]:fade-in-0 data-[closed]:zoom-out-95 data-[expanded]:zoom-in-95 focus-visible:outline-none focus-visible:ring-[1.5px] focus-visible:ring-sea-blue/50'
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        class={cn(baseStyles, local.class)}
        {...rest}
      />
    </DropdownMenuPrimitive.Portal>
  )
}

type dropdownMenuRadioItemProps<T extends ValidComponent = 'div'> = ParentProps<
  DropdownMenuRadioItemProps<T> & {
    class?: string
  }
>

export function DropdownMenuRadioItem<T extends ValidComponent = 'div'>(
  props: PolymorphicProps<T, dropdownMenuRadioItemProps<T>>,
): JSX.Element {
  const [local, rest] = splitProps(props as dropdownMenuRadioItemProps, [
    'class',
    'children',
  ])

  const baseStyles =
    'focus:bg-grey-100 focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none transition-colors text-sm data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[checked]:text-blue'
  return (
    <DropdownMenuPrimitive.RadioItem
      class={cn(baseStyles, local.class)}
      {...rest}
    >
      <DropdownMenuPrimitive.ItemIndicator class="absolute left-2 inline-flex h-4 w-4 items-center justify-center">
        <CheckIcon class="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
      {props.children}
    </DropdownMenuPrimitive.RadioItem>
  )
}

type dropdownMenuCheckboxItemProps<T extends ValidComponent = 'div'> =
  ParentProps<
    DropdownMenuCheckboxItemProps<T> & {
      class?: string
    }
  >

export function DropdownMenuCheckboxItem<T extends ValidComponent = 'div'>(
  props: PolymorphicProps<T, dropdownMenuCheckboxItemProps<T>>,
): JSX.Element {
  const [local, rest] = splitProps(props as dropdownMenuCheckboxItemProps, [
    'class',
    'children',
  ])
  const baseStyles =
    'focus:bg-grey-100 focus:text-accent-foreground relative flex cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none transition-colors text-sm data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[checked]:text-blue'

  return (
    <DropdownMenuPrimitive.CheckboxItem
      class={cn(baseStyles, local.class)}
      {...rest}
    >
      <DropdownMenuPrimitive.ItemIndicator class="absolute left-2 inline-flex h-4 w-4 items-center justify-center">
        <CheckIcon class="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
      {props.children}
    </DropdownMenuPrimitive.CheckboxItem>
  )
}
