export const DEFAULT_PAGE_SIZE = 10
export const MAX_PAGE_SIZE = 50

export const DEFAULT_DISTANCE_KM = 30
export const MAX_DISTANCE_KM = 100

export const HOME_OPT_FILTERS = [
  'pmr',
  'pets-allowed',
  'kids-friendly',
  'outside',
  'free',
] as const

export const STEPS_KM = [
  { val: 1, zoom: 12 },
  { val: 5, zoom: 11 },
  { val: 10, zoom: 10 },
  { val: DEFAULT_DISTANCE_KM, zoom: 9 },
  { val: 50, zoom: 8 },
  { val: MAX_DISTANCE_KM, zoom: 7 },
] as const

export type HomeOptFilter = (typeof HOME_OPT_FILTERS)[number]
