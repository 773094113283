import { type JSX } from 'solid-js'
import { Transition } from 'solid-transition-group'

export function FadeTransition(props: {
  children: JSX.Element
  onExit?: () => void
  duration?: number
}): JSX.Element {
  return (
    <Transition
      onEnter={(el, done) => {
        const a = el.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: props.duration ?? 150,
        })
        void a.finished.then(done)
      }}
      onExit={(el, done) => {
        const a = el.animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: props.duration ?? 150,
        })
        void a.finished.then(done).then(props.onExit)
      }}
    >
      {props.children}
    </Transition>
  )
}
