import { createMediaQuery } from '@solid-primitives/media'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from '@solidjs/router'
import {
  createQuery,
  type CreateQueryResult,
  useQueryClient,
} from '@tanstack/solid-query'
import { isSameDay } from 'date-fns'
import {
  type Accessor,
  createContext,
  createMemo,
  createSignal,
  type JSX,
  untrack,
  useContext,
} from 'solid-js'
import toast from 'solid-toast'
import { doesSessionExist } from 'supertokens-web-js/recipe/session'
import { type Join } from 'type-fest'

import {
  type DateString,
  type Latitude,
  type Longitude,
  type PoiId,
  type ThemeName,
} from '#db/schema.constants'

import {
  getEntitiesToCache,
  getFavoritesThemeNames,
} from './appStores.telefunc'
import { DATE_PRESETS, type DatePresetName } from './components/DatePicker'
import {
  getLocalityByCoordinates,
  getRegionBySlug,
  guessPositionByIp,
} from './geoloc.telefunc'
import { useI18n } from './i18n'
import { getUserProfile } from './pages/auth/auth.telefunc'
import { QUERY_FAVORITE_KEY } from './pages/favorites/favorites.constants'
import {
  addToFavorites,
  getFavoritesIds,
  removeFromFavorites,
} from './pages/favorites/favorites.telefunc'
import {
  DEFAULT_DISTANCE_KM,
  HOME_OPT_FILTERS,
  type HomeOptFilter,
  MAX_DISTANCE_KM,
} from './pages/home/home.constants'
import { assertDateString, clamp } from './utils'

export type EntitiesToCache = Awaited<ReturnType<typeof getEntitiesToCache>>

export type UserInfo = Exclude<
  Awaited<ReturnType<typeof getUserProfile>>,
  undefined
>

export type HomeSearchParams = {
  dates: `${string}-${string}-${string}_${string}-${string}-${string}`
  opts: Join<HomeOptFilter[], ','>
  coords: `${number},${number}`
  dist: `${number}`
}

export type PositionResult = {
  coords: [Longitude, Latitude]
  name: string
} | null

export type AppStoreContext = {
  userInfo: CreateQueryResult<UserInfo | null>
  cachedEntities: CreateQueryResult<EntitiesToCache>
  favoritesIds: CreateQueryResult<PoiId[]>
  favThemesNames: CreateQueryResult<ThemeName[]>
  gutterWidth: Accessor<number>
  setGutterWidth: (width: number) => void
  distance: Accessor<number>
  parsedDateRange: Accessor<[DateString, DateString] | [DateString] | null>
  selectedDatePresets: Accessor<DatePresetName | null>
  optsArr: Accessor<HomeOptFilter[]>
  position: Accessor<PositionResult>
  setFilters: (
    filters: Partial<{
      opts: HomeOptFilter[]
      dateRange: [DateString, DateString] | [DateString] | null
      distance: number | null
      position: { coords: [Longitude, Latitude]; name: string }
    }>,
  ) => void
  toggleFavorite: (poiId: PoiId) => Promise<void>
  isSmallScreen: Accessor<boolean>
}

const appStoresContext = createContext<AppStoreContext>()

export function AppStoresProvider(props: {
  children: JSX.Element
}): JSX.Element {
  const queryClient = useQueryClient()
  const t = useI18n()

  const isSmallScreen = createMediaQuery('(max-width: 40rem)')
  const userInfo = createQuery(() => {
    return {
      queryKey: ['fetchUserInfo'],
      queryFn: async (): Promise<UserInfo | null> => {
        if (await doesSessionExist()) {
          // console.log('fetching user profile...')
          const rst = await getUserProfile()
          return rst ?? null
        }
        return null
      },
    }
  })

  const cachedEntities = createQuery(() => {
    return {
      queryKey: ['getEntitiesToCache'],
      queryFn: async (): Promise<EntitiesToCache> => {
        return await getEntitiesToCache()
      },
    }
  })

  const favoritesIds = createQuery(() => {
    return {
      queryKey: [QUERY_FAVORITE_KEY, 'getFavoritesIds'],
      queryFn: async (): Promise<PoiId[]> => {
        if (await doesSessionExist()) {
          return await getFavoritesIds()
        }
        return []
      },
    }
  })

  const favThemesNames = createQuery(() => {
    return {
      queryKey: ['getFavoritesThemeNames'],
      queryFn: async (): Promise<ThemeName[]> => {
        if (await doesSessionExist()) {
          return await getFavoritesThemeNames()
        }
        return []
      },
    }
  })

  const toggleFavorite = async (poiId: PoiId): Promise<void> => {
    const [user, favIds] = untrack(() => [userInfo.data, favoritesIds.data])

    if (!user) {
      console.error('failed to load user before toggling favorite')
      return
    }
    if (!favIds) {
      console.error('failed to load favoriteIds before toggling')
      return
    }
    if (favIds.includes(poiId)) {
      await removeFromFavorites(poiId)
      toast.success(t('toasts.on_remove_favorite'), { duration: 3000 })
    } else {
      await addToFavorites(poiId)
      toast.success(t('toasts.on_add_favorite'), { duration: 3000 })
    }
    await queryClient.invalidateQueries({
      queryKey: [QUERY_FAVORITE_KEY],
    })
  }

  const [searchParams, setHomeSearchParams] =
    useSearchParams<HomeSearchParams>()

  const distance = createMemo(() => {
    const numDist = Number(searchParams.dist)
    if (isNaN(numDist)) {
      return DEFAULT_DISTANCE_KM
    }
    return clamp(numDist, 0, MAX_DISTANCE_KM)
  })

  const optsArr = createMemo(() => {
    return (
      searchParams.opts
        ?.split(',')
        .filter((s: string): s is HomeOptFilter =>
          HOME_OPT_FILTERS.includes(s),
        ) ?? []
    ).sort((a, b) => a.localeCompare(b))
  })

  const navigate = useNavigate()
  const location = useLocation()

  const [manualLocality, setManualLocality] = createSignal<string | null>(null)
  const localityQuery = createQuery(() => {
    return {
      enabled: manualLocality() == null && searchParams.coords != null,
      queryKey: ['getLocalityByCoordinates', searchParams.coords],
      queryFn: async (): Promise<string | null> => {
        if (searchParams.coords == null) {
          return null
        }
        const [lon, lat] = searchParams.coords.split(',').map(Number) as [
          Longitude,
          Latitude,
        ]
        const res = await getLocalityByCoordinates([lon, lat])
        return res
      },
    }
  })

  function setFilters(
    filters: Partial<{
      opts: HomeOptFilter[]
      dateRange: [DateString, DateString] | [DateString] | null
      distance: number | null
      position: { coords: [Longitude, Latitude]; name: string }
    }>,
  ): void {
    const newSearchParamsEntries = {
      opts: 'opts' in filters ? filters.opts?.join(',') : searchParams.opts,
      dates:
        'dateRange' in filters
          ? filters.dateRange?.join('_')
          : searchParams.dates,
      coords:
        'position' in filters && filters.position != null
          ? `${filters.position.coords[0]},${filters.position.coords[1]}`
          : searchParams.coords,
      dist:
        'distance' in filters
          ? filters.distance !== DEFAULT_DISTANCE_KM
            ? `${filters.distance}`
            : null
          : searchParams.dist,
    }
    if ('position' in filters && filters.position != null) {
      setManualLocality(filters.position.name)
    }
    if (
      params.townSlug != null &&
      'position' in filters &&
      filters.position != null
    ) {
      const path = location.pathname.replace(/ville\/[^/]+\/?/, '')
      const searchParamsEntries = Object.entries(newSearchParamsEntries).filter(
        (entry): entry is [string, string] => entry[1] != null,
      )
      navigate(path + '?' + new URLSearchParams(searchParamsEntries).toString())
      return
    }
    setHomeSearchParams(newSearchParamsEntries)
  }

  const manualPosition = createMemo(() => {
    if (searchParams.coords == null) {
      return null
    }
    return {
      coords: searchParams.coords.split(',').map(Number) as [
        Longitude,
        Latitude,
      ],
      name: manualLocality() ?? localityQuery.data ?? '...',
    }
  })

  const [gutterWidth, setGutterWidth] = createSignal(0)

  const params = useParams<{ townSlug?: string }>()

  const townPositionQuery = createQuery(() => {
    return {
      enabled: params.townSlug != null,
      queryKey: ['getTownPosition', params.townSlug],
      queryFn: async (): Promise<PositionResult> => {
        if (params.townSlug == null) {
          return null
        }
        const res = await getRegionBySlug(params.townSlug)
        return res
      },
    }
  })

  const ipPositionQuery = createQuery(() => {
    return {
      queryKey: ['getIpPosition'],
      queryFn: async (): Promise<PositionResult> => {
        const res = await guessPositionByIp()
        return res
      },
    }
  })

  const geoLocPositionQuery = createQuery(() => {
    return {
      queryKey: ['getGeoLocPosition'],
      queryFn: async (): Promise<PositionResult> => {
        try {
          const res = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject)
            },
          )
          const coords = [res.coords.longitude, res.coords.latitude] as [
            Longitude,
            Latitude,
          ]
          const name = await getLocalityByCoordinates(coords)
          return {
            coords,
            name,
          }
        } catch (err) {
          t('toasts.on_geolocation_error')
          console.warn('unable to fetch position from browser', err)
          return null
        }
      },
    }
  })

  const parsedDateRange = createMemo<
    [DateString, DateString] | [DateString] | null
  >(() => {
    const [start, end] = searchParams.dates?.split('_') ?? [
      undefined,
      undefined,
    ]
    try {
      if (typeof start !== 'undefined') {
        assertDateString(start)
      }
      if (typeof end !== 'undefined') {
        assertDateString(end)
      }

      if (start && end) {
        return [start, end]
      }
      if (start) {
        return [start]
      }
      return null
    } catch (e) {
      console.error(e)
      return null
    }
  })

  const position = createMemo<{
    coords: [Longitude, Latitude]
    name: string
  } | null>((_prev) => {
    if (params.townSlug != null) {
      return townPositionQuery.data ?? null
    }
    return (
      manualPosition() ??
      geoLocPositionQuery.data ??
      ipPositionQuery.data ??
      null
    )
  }, null)

  const selectedDatePresets = createMemo<DatePresetName | null>(() => {
    const [day1, day2] = parsedDateRange() ?? []
    const presetName =
      day1 != null && day2 != null
        ? (DATE_PRESETS.find(
            (p) =>
              isSameDay(p.range[0].toString(), day1) &&
              isSameDay(p.range[1].toString(), day2),
          )?.name ?? null)
        : null
    return presetName
  })

  return (
    <appStoresContext.Provider
      value={{
        userInfo,
        cachedEntities,
        favoritesIds,
        favThemesNames,
        optsArr,
        parsedDateRange,
        selectedDatePresets,
        toggleFavorite,
        distance,
        position,
        setFilters,
        gutterWidth,
        setGutterWidth,
        isSmallScreen,
      }}
    >
      {props.children}
    </appStoresContext.Provider>
  )
}

export function useAppStoresCtx(): AppStoreContext {
  const ctx = useContext(appStoresContext)
  if (!ctx) {
    throw new Error('useAppStoresCtx must be used within a AppStoresProvider')
  }
  return ctx
}
