import {
  type Flatten,
  flatten,
  resolveTemplate,
  type Translator,
  translator,
} from '@solid-primitives/i18n'
import { createContext, type JSX, useContext } from 'solid-js'
import { titleCase } from 'title-case'

import frDict from './locales/fr.json'

export type SupportedLocale = 'fr'

type RawDict = typeof frDict
export type Dictionary = Flatten<RawDict>

const flatFrDict = flatten(frDict)
const t = translator(() => flatFrDict, resolveTemplate)
const I18nContext = createContext<Translator<Dictionary>>(t)

export function I18nProvider(props: { children: JSX.Element }): JSX.Element {
  return <I18nContext.Provider value={t}>{props.children}</I18nContext.Provider>
}

export function useI18n(): Translator<Dictionary> {
  return useContext(I18nContext)
}

export const SMALL_FR_WORDS = new Set([
  'à',
  'après',
  'au',
  'aux',
  'avec',
  'ce',
  'ces',
  'chez',
  'comme',
  'dans',
  'de',
  'des',
  'du',
  'd',
  'en',
  'et',
  'hors',
  'jusque',
  'la',
  'le',
  'les',
  'lors',
  'ne',
  'ni',
  'nos',
  'or',
  'ou',
  'par',
  'pas',
  'pour',
  'près',
  'quand',
  'que',
  'qui',
  'sans',
  'se',
  'selon',
  'si',
  'sous',
  'sur',
  'un',
  'une',
  'vers',
  'y',
  'l',
  'the',
])

export const WORD_FR_SEPARATORS = new Set([
  '—',
  '–',
  '-',
  '―',
  '/',
  '′',
  "'",
  '’',
])

export const SENTENCE_FR_TERMINATORS = new Set(['.', '!', '?'])

export const TITLE_FR_TERMINATORS = new Set([
  ...SENTENCE_FR_TERMINATORS,
  ':',
  '"',
  "'",
  '”',
  ';',
])

const upperCaseRgx = /([A-Z]){4}/g

export function titleCaseIfUpperCase(str?: string | null): string | null {
  if (!str) {
    return null
  }

  if (upperCaseRgx.test(str)) {
    return titleCase(str.toLocaleLowerCase('fr-FR'), {
      locale: 'fr-FR',
      smallWords: SMALL_FR_WORDS,
      wordSeparators: WORD_FR_SEPARATORS,
      sentenceTerminators: SENTENCE_FR_TERMINATORS,
      titleTerminators: TITLE_FR_TERMINATORS,
    })
  }
  return str
}
