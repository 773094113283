import {
  createSignal,
  For,
  type JSX,
  lazy,
  Show,
  Suspense,
  untrack,
} from 'solid-js'

import { useAppStoresCtx } from '../../appStores'
// import { Button } from '../../components/Button'
import { DatePicker } from '../../components/DatePicker'
import {
  DropdownButtonTrigger,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/DropdownMenu'
import { FadeTransition } from '../../components/FadeTransition'
import {
  CalendarIcon,
  ChevronDownIcon,
  // MapIcon,
  SlidersIcon,
} from '../../components/Icon'
import { useI18n } from '../../i18n'
import { cn, removeStyleFromBodyForModal } from '../../utils'
import { HOME_OPT_FILTERS } from './home.constants'
import { MobileSearchMenu } from './MobileSearchMenu'

const CitySelector = lazy(async () => import('./CitySelector'))

export function SearchNav(props: {
  titlePrefix: string
  class?: string
}): JSX.Element {
  const t = useI18n()

  const [openedDate, setOpenedDate] = createSignal(false)
  const [openedOption, setOpenedOption] = createSignal(false)
  const [openCitySelector, setOpenCitySelector] = createSignal(false)
  const {
    position,
    distance,
    // setDateRange,
    // setOptFilters,
    setFilters,
    optsArr,
    parsedDateRange,
    selectedDatePresets,
  } = useAppStoresCtx()

  return (
    <>
      <Suspense>
        <FadeTransition
          onExit={() => {
            removeStyleFromBodyForModal()
          }}
        >
          <Show when={openCitySelector()}>
            <CitySelector
              onCancel={() => setOpenCitySelector(false)}
              onValidate={() => setOpenCitySelector(false)}
            />
          </Show>
        </FadeTransition>
      </Suspense>
      <nav class={cn('sm:pt-10', props.class)}>
        <h1 class="pt-4 text-6xl sm:pt-8">
          {props.titlePrefix}
          {/* Desktop city selector */}
          <button
            class="group ml-2 hidden text-apple-green sm:inline"
            onClick={() => setOpenCitySelector(true)}
          >
            {position()?.name ?? t('home.locality_name_placheholder')}
            <ChevronDownIcon class="ml-2 inline-block size-6 align-middle text-grey-100 opacity-0 transition-opacity group-hover:opacity-100" />
          </button>
          {/* Mobile city indicator */}
          <span class="ml-2 inline text-apple-green sm:hidden">
            {position()?.name ?? t('home.locality_name_placheholder')}
          </span>
        </h1>
        <p class="mt-2 text-grey-300 text-base">
          {t('home.within_a_radius_of', { distance: distance() })}
        </p>

        {/* Desktop menu */}
        <div class="hidden justify-between gap-4 pt-5 sm:flex">
          <div class="flex justify-start gap-4">
            <DropdownMenu open={openedDate()} onOpenChange={setOpenedDate}>
              <DropdownMenuTrigger>
                <DropdownButtonTrigger
                  class="!leading-none"
                  active={Boolean(parsedDateRange() != null)}
                  open={openedDate()}
                  onClickDelete={() => {
                    setFilters({ dateRange: null })
                  }}
                >
                  <CalendarIcon class="h-6 w-6" />
                  <div>
                    <Show
                      when={selectedDatePresets()}
                      fallback={t('home.date_label')}
                    >
                      {(preset) => t(`date_picker.${preset()}`)}
                    </Show>
                  </div>
                </DropdownButtonTrigger>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                class="border-none bg-transparent p-0"
                style={{
                  'transform-origin': 'var(--kb-menu-content-transform-origin)',
                }}
              >
                <DatePicker
                  value={parsedDateRange()}
                  onChange={(val) => {
                    setFilters({ dateRange: val })
                  }}
                />
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu open={openedOption()} onOpenChange={setOpenedOption}>
              <DropdownMenuTrigger>
                <DropdownButtonTrigger
                  class="max-w-[350px] !leading-none"
                  active={optsArr().length > 0}
                  open={openedOption()}
                  onClickDelete={() => {
                    setFilters({ opts: [] })
                  }}
                >
                  <SlidersIcon class="h-6 w-6 flex-shrink-0" />
                  <Show
                    when={optsArr().length > 0}
                    fallback={<span>{t('home.options_label')}</span>}
                  >
                    <div class="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                      {optsArr()
                        .map((o) => t(`home.filters.${o}`))
                        .join(', ')}
                    </div>
                  </Show>
                </DropdownButtonTrigger>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                style={{
                  'transform-origin': 'var(--kb-menu-content-transform-origin)',
                }}
              >
                <For each={HOME_OPT_FILTERS}>
                  {(opt) => (
                    <DropdownMenuCheckboxItem
                      class="pr-12"
                      checked={optsArr().includes(opt)}
                      onClick={() => {
                        const _opts = untrack(() => optsArr())
                        const opts = _opts.includes(opt)
                          ? _opts.filter((o) => o !== opt)
                          : [..._opts, opt]
                        setFilters({ opts })
                      }}
                    >
                      {t(`home.filters.${opt}`)}
                    </DropdownMenuCheckboxItem>
                  )}
                </For>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          {/* <Button variant="outline" class="px-6 py-3">
            <MapIcon class="h-6 w-6 pr-2 text-apple-green" />
            {t('home.map')}
          </Button> */}
        </div>
      </nav>

      {/* Mobile bottom nav */}

      <MobileSearchMenu />
    </>
  )
}
