import type { ButtonRootProps } from '@kobalte/core/button'
import { Button as ButtonPrimitive } from '@kobalte/core/button'
import type { PolymorphicProps } from '@kobalte/core/polymorphic'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { JSX, ParentProps, ValidComponent } from 'solid-js'
import { Show, splitProps } from 'solid-js'

import { Icon, type IconName } from '#/components/Icon'
import { cn } from '#/utils'

const baseStyles =
  'text-nowrap inline-flex items-center h-fit font-semibold justify-center rounded-full uppercase transition-all hover:cursor-pointer'
const focusStyles =
  'focus-visible:outline-none focus-visible:ring-[1.5px] focus-visible:ring-sea-blue'
const disabledStyles =
  'disabled:pointer-events-none disabled:opacity-50 hover:border-2 hover:border-light-50'

export const buttonVariants = cva(cn(baseStyles, focusStyles, disabledStyles), {
  variants: {
    variant: {
      default: 'text-grey-50 border-2 border-transparent hover:border-grey-50',
      invert:
        'text-medium-blue border-2 border-transparent hover:border-medium-blue',
      solid:
        'bg-grey-50 text-medium-blue border-2 border-grey-50 hover:border-light-30',
      solidInvert: 'bg-medium-blue text-grey-50 border-2 border-transparent',
      outline: 'text-grey-50 border-2 border-grey-50 hover:border-light-65',
      outlineInvert:
        'text-medium-blue border-2 border-medium-blue hover:border-dark-50',
      primary: 'bg-apple-green text-medium-blue border-2 border-transparent',
      link: 'text-grey-50 hover:underline  border border-transparent',
    },
    size: {
      default: 'px-7 py-4 text-xs',
      sm: 'py-2.5 px-4 text-2xs',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
  compoundVariants: [
    {
      variant: 'link',
      size: ['default', 'sm'],
      class: 'px-0 py-1 border-none',
    },
  ],
})

const iconVariants = cva(undefined, {
  variants: {
    size: {
      default: 'h-6 w-6',
      sm: 'h-4 w-4',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

type ButtonProps<T extends ValidComponent = 'button'> = ButtonRootProps<T> &
  VariantProps<typeof buttonVariants> & {
    class?: string
    iconClass?: string
  } & {
    rightIcon?: IconName
    leftIcon?: IconName
  }

export function Button<T extends ValidComponent = 'button'>(
  props: PolymorphicProps<T, ButtonProps<T>>,
): JSX.Element {
  const [local, rest] = splitProps(props as ParentProps<ButtonProps>, [
    'class',
    'variant',
    'size',
    'children',
    'rightIcon',
    'leftIcon',
    'iconClass',
  ])

  return (
    <ButtonPrimitive
      class={cn(
        buttonVariants({
          size: local.size,
          variant: local.variant,
        }),
        local.class,
      )}
      {...rest}
    >
      <Show when={local.leftIcon}>
        {(icon) => (
          <Icon
            name={icon()}
            class={cn(
              'mr-2',
              iconVariants({ size: local.size }),
              local.iconClass,
            )}
          />
        )}
      </Show>
      {local.children}
      <Show when={local.rightIcon}>
        {(icon) => (
          <Icon
            name={icon()}
            class={cn(
              'ml-2',
              iconVariants({ size: local.size }),
              local.iconClass,
            )}
          />
        )}
      </Show>
    </ButtonPrimitive>
  )
}
