import { A, useLocation, useParams } from '@solidjs/router'
import { cva, type VariantProps } from 'class-variance-authority'
import { isSameDay, isSameMonth, parse } from 'date-fns'
import { createSignal, type JSX, Show } from 'solid-js'

import {
  type DateString,
  type MediaFileId,
  type PoiId,
  type ThemeName,
  type TimeString,
} from '../db/schema.constants'
import { titleCaseIfUpperCase, useI18n } from '../i18n'
import { getPlaceholderUrl } from '../pages/detail/placeholder'
import { capitalizeFirstLetter, cn, shuffle } from '../utils'
import { FadeTransition } from './FadeTransition'
import { FavoriteButton } from './FavoriteButton'
import { HeartIcon, InfoCircleIcon } from './Icon'

const wrapperVariants = cva(undefined, {
  variants: {
    size: {
      default: '~w-56/71',
      lg: '~w-64/96',
      fluid: '',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

const imgVariants = cva(undefined, {
  variants: {
    size: {
      default: 'aspect-[1.4]',
      lg: 'aspect-video',
      fluid: 'aspect-video md:aspect-[1.4]',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export const frDateFormatter = new Intl.DateTimeFormat('fr-FR', {
  month: 'short',
  day: 'numeric',
  weekday: 'short',
})

export const frDateFormatterNoMonth = new Intl.DateTimeFormat('fr-FR', {
  day: 'numeric',
  weekday: 'short',
})

export function EventCard(
  props: {
    poiId: PoiId
    slugFr: string
    themeName: ThemeName
    distanceKm?: number
    title: string | null
    startDate: DateString | null
    endDate: DateString | null
    poiTime: TimeString | null
    locality: string | null
    mediaFileId: MediaFileId | null
    mainMediaFileCredits: string | null
    mainMediaFileLicense: string | null
    randomIdx: number
  } & VariantProps<typeof wrapperVariants>,
): JSX.Element {
  const t = useI18n()

  const [showCredits, setShowCredits] = createSignal(false)

  const params = useParams<{ townSlug?: string }>()
  const location = useLocation()

  return (
    <article
      class={cn(
        'text-grey-50 transition-[filter] duration-300 ease-in-out hover:contrast-150',
        wrapperVariants({ size: props.size }),
      )}
    >
      <A
        draggable={false}
        href={`${
          params.townSlug != null ? `/ville/${params.townSlug}` : ''
        }/evenement/${props.slugFr}${location.search}`}
      >
        <div
          class={cn(
            'observable-size relative w-full select-none overflow-hidden rounded-lg bg-medium-blue',
            imgVariants({ size: props.size }),
          )}
          id={`event-card-observable-${props.slugFr}`}
        >
          <img
            draggable={false}
            class={cn(
              'block aspect-auto w-full bg-medium-blue object-cover after:absolute after:inset-0 after:block after:size-full after:bg-medium-blue after:content-[""]',
              imgVariants({ size: props.size }),
            )}
            src={
              props.mediaFileId
                ? `/mediafile/${props.mediaFileId}?thumb=1`
                : getPlaceholderUrl(props.themeName, props.randomIdx)
            }
            alt=""
          />
          <FadeTransition>
            <Show when={showCredits()}>
              <div class="absolute bottom-0 left-0 right-0 h-10 w-full bg-gradient-to-t from-black/50 to-transparent" />
            </Show>
          </FadeTransition>

          <div class="absolute bottom-0 left-0 right-0 flex w-full items-end gap-1 p-2">
            <Show
              when={
                props.mainMediaFileCredits != null ||
                props.mainMediaFileLicense != null
              }
            >
              <div
                onMouseEnter={() => {
                  setShowCredits(true)
                }}
                onMouseLeave={() => {
                  setShowCredits(false)
                }}
                class="rounded-full bg-dark-30 text-light-50 hover:text-grey-50"
              >
                <InfoCircleIcon class="size-4" />
              </div>
            </Show>
            <div class="flex-1 text-2xs">
              <Show when={showCredits()}>
                {[props.mainMediaFileCredits, props.mainMediaFileLicense]
                  .filter(Boolean)
                  .join(' - ')}
              </Show>
            </div>
            <FavoriteButton poiId={props.poiId}>
              {(handleClick, isFavorite, loading) => (
                <button
                  type="button"
                  aria-label={
                    isFavorite
                      ? t('event_card.remove_from_favorites', {
                          title: props.title ?? '',
                        })
                      : t('event_card.add_to_favorites', {
                          title: props.title ?? '',
                        })
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleClick()
                  }}
                  disabled={loading}
                  class={cn(
                    'size-7 justify-self-end rounded-full bg-grey-50',
                    isFavorite
                      ? 'bg-grey-50 text-coral'
                      : 'bg-light-50 text-medium-blue hover:bg-light-80',
                  )}
                >
                  <HeartIcon class="mx-auto my-auto size-4" />
                </button>
              )}
            </FavoriteButton>
          </div>
        </div>
        <div class="space-y-0.5 py-2 shadow-black drop-shadow">
          <h3 class="line-clamp-3 text-balance font-semibold text-base">
            {titleCaseIfUpperCase(props.title)}
          </h3>
          <p class="text-grey-300">
            <Show when={props.locality}>
              <span>{props.locality}</span>
            </Show>
            <Show when={props.distanceKm}>
              {(d) => (
                <span class="text-sm">
                  &nbsp;·&nbsp;
                  {t('event_card.distance_km', {
                    distance: d().toFixed(d() > 1 ? 0 : 1),
                  })}
                </span>
              )}
            </Show>
          </p>
          <Show when={props.startDate}>
            {(d) => {
              const start = new Date(d())
              const end = props.endDate ? new Date(props.endDate) : null
              const sameDay = end ? isSameDay(start, end) : false
              const sameMonth = end ? isSameMonth(start, end) : false

              const startStr = capitalizeFirstLetter(
                sameMonth && !sameDay
                  ? frDateFormatterNoMonth.format(start)
                  : frDateFormatter.format(start),
              ).replaceAll('.', '')

              const endStr = end
                ? capitalizeFirstLetter(frDateFormatter.format(end)).replaceAll(
                    '.',
                    '',
                  )
                : null

              const timeStr = props.poiTime
                ? ((): string | null => {
                    try {
                      return parse(
                        props.poiTime,
                        'HH:mm:ss',
                        new Date(),
                      ).toLocaleTimeString('fr-FR', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    } catch {
                      return null
                    }
                  })()
                : null

              return (
                <p class="text-apple-green">
                  {!sameDay && endStr
                    ? t('event_card.date_period', { startStr, endStr })
                    : startStr}
                  <Show when={timeStr}>
                    <span>&nbsp;·&nbsp;{timeStr}</span>
                  </Show>
                </p>
              )
            }}
          </Show>
        </div>
      </A>
    </article>
  )
}

export function SkeletonEventCard(
  props: VariantProps<typeof wrapperVariants>,
): JSX.Element {
  const [w0, w1, w2] = shuffle(['w-40', 'w-24', 'w-32'])
  return (
    <article
      class={cn(
        'text-grey-50 transition-[filter] duration-300 ease-in-out hover:contrast-150',
        wrapperVariants({ size: props.size }),
      )}
    >
      <div class="w-full">
        <div
          class={cn(
            'observable-size w-full animate-pulse rounded-lg bg-grey-700',
            imgVariants({ size: props.size }),
          )}
        />
        <div class="animate-pulse py-4">
          <div class={cn(w0, 'mb-2 h-6 rounded bg-grey-50')} />
          <div class={cn(w1, 'mb-2 h-4 rounded bg-grey-300')} />
          <div class={cn(w2, 'h-4 rounded bg-apple-green')} />
        </div>
      </div>
    </article>
  )
}

export default function SeparatorCard(
  props: VariantProps<typeof wrapperVariants> & { msg: string },
): JSX.Element {
  return (
    <article class={wrapperVariants({ size: props.size })}>
      <div
        class={cn(
          'observable-size w-full content-center rounded-lg bg-dark-30 p-8 text-center text-grey-50',
          imgVariants({ size: props.size }),
        )}
      >
        {props.msg}
      </div>
    </article>
  )
}
