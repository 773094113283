import { A } from '@solidjs/router'
import { type JSX, type ParentProps } from 'solid-js'

import { ArrowLeftIcon } from '#/components/Icon'
import logoUrl from '#/components/logo.webp'
import { useI18n } from '#/i18n'

import { sample } from '../../utils'
import pic0url from './auth-0.webp'
import pic1url from './auth-1.webp'
import pic2url from './auth-2.webp'
import pic3url from './auth-3.webp'

export function AuthLayout(props: ParentProps): JSX.Element {
  const picUrl = sample([pic0url, pic1url, pic2url, pic3url])

  const t = useI18n()
  return (
    <div class="lkl-full-section">
      <div class="grid grid-cols-1 gap-8 py-6 lg:grid-cols-2">
        <div class="gap flex flex-col gap-2 lg:gap-8">
          <div class="-mt-6 flex h-24 items-center justify-between">
            <A href="/" class="w-32">
              <img src={logoUrl} />
            </A>

            <A href="/" class="flex items-center text-xs hover:underline">
              <ArrowLeftIcon class="mr-2 inline-block size-4" />
              {t('common_back')}
            </A>
          </div>
          {props.children}
        </div>
        <div>
          <div
            class="sticky top-6 hidden h-[calc(100vh-3rem)] flex-col justify-end overflow-hidden rounded-2xl bg-cover bg-center drop-shadow-2xl lg:flex"
            style={{ 'background-image': `url(${picUrl})` }}
          >
            <p class="w-full text-balance bg-gradient-to-t from-black/50 to-transparent px-12 py-12 pb-12 pt-36 !leading-snug text-grey-50 text-3xl">
              {t('auth.caption')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
