import { MetaProvider } from '@solidjs/meta'
import { Router } from '@solidjs/router'
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query'
import { type JSX, lazy, Suspense } from 'solid-js'
import { Toaster } from 'solid-toast'

import { RootLayout } from '#/components/RootLayout'
import { ROUTES } from '#/router'

import { I18nProvider } from './i18n'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const SolidQueryDevtools = import.meta.env.DEV
  ? lazy(async () => import('./TanStackQueryDevTool'))
  : (): JSX.Element => null

export default function App(): JSX.Element {
  return (
    <MetaProvider>
      <QueryClientProvider client={queryClient}>
        <I18nProvider>
          <Router root={RootLayout}>{ROUTES}</Router>
          <Toaster position="top-center" gutter={8} />
        </I18nProvider>
        <Suspense>
          <SolidQueryDevtools />
        </Suspense>
      </QueryClientProvider>
    </MetaProvider>
  )
}
