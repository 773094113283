import {
  A,
  // useBeforeLeave,
  useCurrentMatches,
  useLocation,
  useNavigate,
  useParams,
} from '@solidjs/router'
import {
  createEffect,
  createMemo,
  type JSX,
  Match,
  onMount,
  type ParentProps,
  Show,
  Suspense,
  Switch,
} from 'solid-js'
import { Portal } from 'solid-js/web'
import toast from 'solid-toast'
import { onAbort } from 'telefunc/client'

import { AppStoresProvider, useAppStoresCtx } from '../appStores'
import { useI18n } from '../i18n'
import blueBlobUrl from './bg-blue-blob.webp'
import bgCityMapUrl from './bg-city-map.svg'
import greenBlobUrl from './bg-green-blob.webp'
import pinkBlobUrl from './bg-pink-blob.webp'
import { FadeTransition } from './FadeTransition'
import { Footer } from './Footer'
import { Header } from './Header'
import { HeartIcon, HomeIcon, InfoCircleIcon, UserIcon } from './Icon'

const URL_MAPPER = {
  pink: pinkBlobUrl,
  green: greenBlobUrl,
  blue: blueBlobUrl,
} as const

const MOBILE_BOTTOM_BAR_ID = 'mobile-bottom-bar'

export function RootLayout(props: ParentProps): JSX.Element {
  const matches = useCurrentMatches()

  const isolationMode = createMemo(() => {
    return (
      matches().findIndex((match) => match.route.info?.isolationMode) !== -1
    )
  })

  // useBeforeLeave(() => {
  //   if ('pluginWebUpdateNotice_' in window) {
  //     window.pluginWebUpdateNotice_.checkUpdate()
  //   }
  // })

  const bgColor = createMemo(() => {
    return (
      (matches().find((match) => match.route.info?.bgColor != null)?.route.info
        ?.bgColor as undefined | 'pink' | 'green' | 'blue') ?? 'green'
    )
  })

  return (
    <AppStoresProvider>
      <div class="relative w-full">
        {/* Background image with gradient */}
        <FadeTransition duration={450}>
          <Switch>
            {
              // eslint-disable-next-line solid/prefer-for
              (['pink', 'green', 'blue'] as const).map((color) => (
                <Match when={bgColor() === color}>
                  <div
                    class="fixed left-0 top-0 h-screen w-screen bg-cover bg-fixed bg-center"
                    style={{ 'background-image': `url(${URL_MAPPER[color]})` }}
                  />
                </Match>
              ))
            }
          </Switch>
        </FadeTransition>
        <div
          id="bg-city-map"
          class="fixed left-0 top-0 h-screen w-screen bg-cover bg-fixed bg-left-top"
          style={{ 'background-image': `url(${bgCityMapUrl})` }}
        />

        {/* Children */}
        <div class="relative">
          <div class="lkl-container flex min-h-screen flex-col content-start">
            <GutterMeasure />
            <Show when={!isolationMode()}>
              <Header />
            </Show>
            <Suspense>{props.children}</Suspense>
            <Show when={!isolationMode()}>
              <Footer />
            </Show>
          </div>
        </div>
      </div>
      <div
        id={MOBILE_BOTTOM_BAR_ID}
        class="fixed bottom-0 left-0 right-0 flex w-full flex-col justify-end"
      >
        <Show when={!isolationMode()}>
          <MobileMenu />
        </Show>
      </div>
      <AbortManager />
    </AppStoresProvider>
  )
}

function MobileMenu(): JSX.Element {
  const t = useI18n()
  const params = useParams<{ townSlug?: string }>()
  const location = useLocation()

  return (
    <div class="order-last flex w-full justify-between bg-dark-blue sm:hidden">
      <A
        href={`${params.townSlug != null ? `/ville/${params.townSlug}` : '/'}${location.search}`}
        class="flex flex-1 justify-start py-3.5 pl-7"
        aria-label={t('accessibility.home_button')}
      >
        <HomeIcon class="size-6" />
      </A>
      <A
        href={`/profile${location.search}`}
        class="flex flex-1 justify-center py-3.5"
        aria-label={t('accessibility.profile_button')}
      >
        <UserIcon class="size-6" />
      </A>
      <A
        href={`/favorites${location.search}`}
        class="flex flex-1 justify-end py-3.5 pr-7"
        aria-label={t('accessibility.fav_button')}
      >
        <HeartIcon class="size-6" />
      </A>
    </div>
  )
}

export function AbortManager(): JSX.Element {
  const navigate = useNavigate()
  const t = useI18n()
  const matches = useCurrentMatches()
  const navigator = useNavigate()
  const { userInfo } = useAppStoresCtx()

  createEffect(() => {
    onAbort(() => {
      console.warn('onAbort redirect to singin page...')
      toast.error(t('toasts.on_abort'), {
        duration: 3000,
        icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
      })
      navigate('/auth/login')
    })
  })

  createEffect(() => {
    const authRequired =
      matches().findIndex((match) => match.route.info?.authRequired) !== -1
    if (authRequired && userInfo.data == null) {
      navigator('/auth/login')
      toast.error(t('toasts.on_abort'), {
        duration: 3000,
        icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
      })
    }
  })
  return null
}

export function MobilePortal(props: ParentProps): JSX.Element {
  return (
    <Portal mount={document.getElementById(MOBILE_BOTTOM_BAR_ID) ?? undefined}>
      {props.children}
    </Portal>
  )
}

export function GutterMeasure(): JSX.Element {
  let containerRef: HTMLDivElement | undefined

  function handleWindoWResize(): void {
    if (containerRef) {
      setGutterWidth((window.innerWidth - containerRef.offsetWidth) / 2)
    }
  }
  onMount(handleWindoWResize)
  window.onresize = handleWindoWResize

  const { setGutterWidth } = useAppStoresCtx()
  return <div ref={containerRef} />
}
