import { isAfter } from 'date-fns'
import { and, eq, inArray, sql } from 'drizzle-orm'
import { type Logger } from 'pino'
import { getContext } from 'telefunc'

import { db, schema } from '#/db'
import { jsonTextPlaceholder } from '#/db/jsonb-field'
import { type DateString } from '#db/schema.constants'
import {
  type Latitude,
  type Longitude,
  type MediaFileId,
  type PoiId,
  type ThemeName,
  type TimeString,
  TRACKING_EVENTS_POI,
  TRACKING_EVENTS_THEME,
} from '#db/schema.constants'

import { assertDateString, clamp } from '../../utils'
import {
  type HomeOptFilter,
  MAX_DISTANCE_KM,
  MAX_PAGE_SIZE,
} from './home.constants'

export type GetPoisResponseItem = {
  totalCount: number
  id: PoiId
  slugFr: string
  label: string | null
  mainMediaFileId: MediaFileId | null
  mainMediaFileCredits: string | null
  mainMediaFileLicense: string | null
  startDate: DateString | null
  endDate: DateString | null
  poiTime: TimeString | null
  locality: string | null
  coordinates: [Longitude, Latitude] | null
  distance: number
}

const filterPmrTerm = sql`CASE WHEN ${sql.placeholder(
  'filterPmr',
)} THEN ${schema.poiExtraFeatures.reduced_mobility} = true ELSE true END`

const filterPetsAllowedTerm = sql`CASE WHEN ${sql.placeholder(
  'filterPetsAllowed',
)} THEN ${schema.poiExtraFeatures.pet_allowed} = true ELSE true END`

const filterKidsFriendlyTerm = sql`CASE WHEN ${sql.placeholder(
  'filterKidsFriendly',
)} THEN ${schema.poiExtraFeatures}.${schema.poiExtraFeatures.kids_friendly} = true OR ${schema.poiExtraThemes}.${schema.poiExtraThemes.kids_friendly} = true OR ${schema.poiExtraPrice.has_child_rate} = true ELSE true END`

const filterOutsideTerm = sql`CASE WHEN ${sql.placeholder(
  'filterOutside',
)} THEN ${schema.poiExtraFeatures}.${schema.poiExtraFeatures.outside} = true OR  ${schema.poiExtraThemes}.${schema.poiExtraThemes.outside} = true ELSE true END`

const filterFreeTerm = sql`CASE WHEN ${sql.placeholder(
  'filterFree',
)} THEN ${schema.poiExtraPrice.has_price_greater_than_zero} = false ELSE true END`

const excludePoiIdTerm = sql`CASE WHEN ${sql.placeholder(
  'excludePoiId',
)}::uuid is null THEN true ELSE ${schema.pointOfInterest.id} != ${sql.placeholder(
  'excludePoiId',
)}::uuid::uuid END`

const disabledTerm = sql`CASE WHEN ${schema.pointOfInterest.disabled} THEN false ELSE true END`

const params = db.$with('params').as(
  db
    .select({
      refPoint: sql`ST_SetSRID(ST_MakePoint(${sql.placeholder(
        'longitude',
      )}, ${sql.placeholder('latitude')}), 4326)`.as('ref_point'),
    })
    .from(schema.address),
)

const preparedGetPoisWithCount = db
  .with(params)
  .select({
    totalCount: sql<number>`COUNT(*) OVER()`.as('total_count'),
    id: schema.pointOfInterest.id,
    label: jsonTextPlaceholder(schema.pointOfInterest.label, 'label'),
    mainMediaFileId: sql<MediaFileId | null>`${schema.mediaFile.id}`.as(
      'main_media_file_id',
    ),
    mainMediaFileCredits: sql<string | null>`${schema.mediaFile.credits}`.as(
      'main_media_file_credits',
    ),
    mainMediaFileLicense: sql<string | null>`${schema.mediaFile.license}`.as(
      'main_media_file_license',
    ),
    slugFr: schema.pointOfInterest.slugFr,
    startDate: schema.event.startDate,
    endDate: schema.event.endDate,
    poiTime: schema.poiExtraTime.poiTime,
    locality: schema.address.locality,
    coordinates: schema.address.coordinates,
    distance:
      sql<number>`ST_Distance(ST_TRANSFORM(${schema.address.coordinates},  3857), ST_TRANSFORM((SELECT ${params.refPoint} FROM ${params} LIMIT 1),  3857))`.as(
        'distance',
      ),
  })
  .from(schema.pointOfInterest)
  .leftJoin(
    schema.address,
    eq(schema.pointOfInterest.addressId, schema.address.id),
  )
  .leftJoin(
    schema.poisToThemes,
    eq(schema.pointOfInterest.id, schema.poisToThemes.poiId),
  )
  .leftJoin(schema.event, eq(schema.event.poiId, schema.pointOfInterest.id))
  .leftJoin(
    schema.poisToMediaFiles,
    and(
      eq(schema.pointOfInterest.id, schema.poisToMediaFiles.poiId),
      eq(schema.poisToMediaFiles.isMain, sql`true`),
    ),
  )
  .leftJoin(
    schema.mediaFile,
    eq(schema.poisToMediaFiles.mediaFileId, schema.mediaFile.id),
  )
  .leftJoin(
    schema.poiExtraTime,
    eq(schema.pointOfInterest.id, schema.poiExtraTime.poiId),
  )
  .leftJoin(
    schema.poiExtraPrice,
    eq(schema.pointOfInterest.id, schema.poiExtraPrice.poi_id),
  )
  .leftJoin(
    schema.poiExtraFeatures,
    eq(schema.pointOfInterest.id, schema.poiExtraFeatures.poi_id),
  )
  .leftJoin(
    schema.poiExtraThemes,
    eq(schema.pointOfInterest.id, schema.poiExtraThemes.poi_id),
  )
  .where((fields) => {
    const bboxTerm = sql`ST_DWithin(${schema.address.coordinates}, (SELECT ${params.refPoint} FROM ${params} LIMIT 1), ${sql.placeholder('distance')}, true)`
    const distanceTerm = sql`(${fields.distance} <= ${sql.placeholder('distance')})`
    const themeTerm = sql`(${schema.poisToThemes.themeName} = ${sql.placeholder('theme')})`
    const dateTerm = sql`(${fields.startDate} is null or ${fields.startDate} >= now())`

    const filterDateTerm = sql`CASE WHEN ${sql.placeholder(
      'filterStartDate',
    )}::date is null OR ${sql.placeholder(
      'filterEndDate',
    )}::date is null OR ${fields.startDate} is null THEN true  ELSE ${fields.startDate} <= ${sql.placeholder('filterEndDate')}::date AND ${fields.endDate} >= ${sql.placeholder('filterStartDate')}::date END`

    return and(
      bboxTerm,
      distanceTerm,
      themeTerm,
      dateTerm,
      filterDateTerm,
      filterPmrTerm,
      filterPetsAllowedTerm,
      filterKidsFriendlyTerm,
      filterOutsideTerm,
      filterFreeTerm,
      excludePoiIdTerm,
      disabledTerm,
    )
  })
  .orderBy(
    // Sort by start date, nulls last
    sql`COALESCE(${schema.event.startDate}, '9999-12-31')`,
    sql`COALESCE(${schema.poiExtraTime.poiTime}, '00:00:00')`,
    // Sort by distance when startDate is null
    sql`${schema.address.coordinates} <-> (SELECT ${params.refPoint} FROM ${params} LIMIT 1)`,
  )
  .limit(sql.placeholder('limit'))
  .offset(sql.placeholder('offset'))
  .prepare('get_pois_with_count')

console.log('preparedGetPoisWithCount => ', preparedGetPoisWithCount.getQuery())

export async function getPoisByAddressDistance({
  pos: [longitude, latitude],
  distanceKm,
  themeName,
  filtersOpts,
  filterDate,
  excludePoiId,
  offset,
  pageSize,
  auto_extend,
}: {
  // do not use tagged type because of telefunc shields
  pos: [longitude: number, latitude: number]
  distanceKm: number
  // do not use tagged type because of telefunc shields
  themeName: string
  filtersOpts: HomeOptFilter[]
  // do not use tagged type because of telefunc shields
  filterDate: [string, string] | [string] | null
  // do not use tagged type because of telefunc shields
  excludePoiId: string | null
  offset: number
  pageSize: number
  auto_extend: boolean
}): Promise<GetPoisResponseItem[]> {
  const { lang, logger } = getContext()
  try {
    const [filterStartDate, filterEndDate] = parseDateRange(
      filterDate?.[0],
      filterDate?.[1],
      logger,
    )
    const distance = clamp(distanceKm, 0, MAX_DISTANCE_KM) * 1000
    const limit = clamp(pageSize, 0, MAX_PAGE_SIZE)

    const values = {
      longitude,
      latitude,
      distance,
      lang,
      filterStartDate,
      filterEndDate,
      filterPmr: filtersOpts.includes('pmr'),
      filterPetsAllowed: filtersOpts.includes('pets-allowed'),
      filterKidsFriendly: filtersOpts.includes('kids-friendly'),
      filterOutside: filtersOpts.includes('outside'),
      filterFree: filtersOpts.includes('free'),
      theme: themeName,
      limit,
      offset,
      excludePoiId: excludePoiId ?? null,
    }
    let result = await preparedGetPoisWithCount.execute(values)

    if (auto_extend && result.length < limit && distanceKm < MAX_DISTANCE_KM) {
      result = await preparedGetPoisWithCount.execute({
        ...values,
        distance: MAX_DISTANCE_KM * 1000,
      })
    }
    return result
  } catch (err) {
    logger.error(err, 'getPoisByAddressDistance error')
    throw new Error('unexpected-error')
  }
}

const preparedGetPromotedPois = db
  .with(params)
  .select({
    totalCount: sql<number>`COUNT(*) OVER()`.as('total_count'),
    id: sql<PoiId>`${schema.pointOfInterest.id}`,
    label: jsonTextPlaceholder(schema.pointOfInterest.label, 'label'),
    mainMediaFileId: sql<MediaFileId | null>`${schema.mediaFile.id}`.as(
      'main_media_file_id',
    ),
    mainMediaFileCredits: sql<string | null>`${schema.mediaFile.credits}`.as(
      'main_media_file_credits',
    ),
    mainMediaFileLicense: sql<string | null>`${schema.mediaFile.license}`.as(
      'main_media_file_license',
    ),

    campaignStartDate: schema.promotionCampaign.startDate,
    campaignEndDate: schema.promotionCampaign.endDate,
    campaignStatus: schema.promotionCampaign.status,
    campaignCreatedAt: schema.promotionCampaign.createdAt,

    slugFr: sql<string>`${schema.pointOfInterest.slugFr}`,
    startDate: schema.event.startDate,
    endDate: schema.event.endDate,
    poiTime: schema.poiExtraTime.poiTime,
    locality: schema.address.locality,
    coordinates: schema.address.coordinates,
    distance:
      sql<number>`ST_Distance(ST_TRANSFORM(${schema.address.coordinates},  3857), ST_TRANSFORM((SELECT ${params.refPoint} FROM ${params} LIMIT 1),  3857))`.as(
        'distance',
      ),
    theme: sql<ThemeName | null>`
      (SELECT theme_name FROM ${schema.poisToThemes} WHERE poi_id = ${
        schema.pointOfInterest.id
      } ORDER BY theme_name LIMIT 1)
    `.as('theme'),
  })
  .from(schema.promotionCampaign)
  .leftJoin(
    schema.pointOfInterest,
    eq(schema.pointOfInterest.id, schema.promotionCampaign.poiId),
  )
  .leftJoin(
    schema.address,
    eq(schema.pointOfInterest.addressId, schema.address.id),
  )
  .leftJoin(schema.event, eq(schema.event.poiId, schema.pointOfInterest.id))
  .leftJoin(
    schema.poisToMediaFiles,
    and(
      eq(schema.pointOfInterest.id, schema.poisToMediaFiles.poiId),
      eq(schema.poisToMediaFiles.isMain, sql`true`),
    ),
  )
  .leftJoin(
    schema.mediaFile,
    eq(schema.poisToMediaFiles.mediaFileId, schema.mediaFile.id),
  )
  .leftJoin(
    schema.poiExtraTime,
    eq(schema.pointOfInterest.id, schema.poiExtraTime.poiId),
  )
  .leftJoin(
    schema.poiExtraPrice,
    eq(schema.pointOfInterest.id, schema.poiExtraPrice.poi_id),
  )
  .leftJoin(
    schema.poiExtraFeatures,
    eq(schema.pointOfInterest.id, schema.poiExtraFeatures.poi_id),
  )
  .leftJoin(
    schema.poiExtraThemes,
    eq(schema.pointOfInterest.id, schema.poiExtraThemes.poi_id),
  )
  .where((fields) => {
    const promotionTerm = sql`(${fields.campaignStatus} = 'active') AND (${
      fields.campaignStartDate
    } <= now()::date) AND (${fields.campaignEndDate} >= now()::date)`

    const bboxTerm = sql`ST_DWithin(${schema.address.coordinates}, (SELECT ${params.refPoint} FROM ${params} LIMIT 1), ${sql.placeholder('distance')}, true)`
    const distanceTerm = sql`(${fields.distance} <= ${sql.placeholder('distance')})`

    const themeTerm = sql`((${sql.placeholder(
      'theme',
    )}::text IS NULL) OR EXISTS (SELECT 1 FROM ${schema.poisToThemes} WHERE ${
      schema.poisToThemes.poiId
    } = ${schema.pointOfInterest.id} AND ${
      schema.poisToThemes.themeName
    } = ${sql.placeholder('theme')}::text))`

    const dateTerm = sql`(${fields.startDate} is null or ${fields.startDate} >= now())`

    const filterDateTerm = sql`CASE WHEN ${sql.placeholder(
      'filterStartDate',
    )}::date is null OR ${sql.placeholder(
      'filterEndDate',
    )}::date is null OR ${fields.startDate} is null THEN true  ELSE ${fields.startDate} <= ${sql.placeholder('filterEndDate')}::date AND ${fields.endDate} >= ${sql.placeholder('filterStartDate')}::date END`

    return and(
      disabledTerm,
      promotionTerm,
      bboxTerm,
      distanceTerm,
      themeTerm,
      dateTerm,
      filterDateTerm,
      filterPmrTerm,
      filterPetsAllowedTerm,
      filterKidsFriendlyTerm,
      filterOutsideTerm,
      filterFreeTerm,
    )
  })
  .orderBy(
    // Sort by start date, nulls last
    sql`COALESCE(${schema.event.startDate}, '9999-12-31')`,
    sql`COALESCE(${schema.promotionCampaign.startDate}, '9999-12-31')`,
    // Sort by distance when startDate is null
    sql`${schema.address.coordinates} <-> (SELECT ${params.refPoint} FROM ${params} LIMIT 1)`,
  )
  .limit(sql.placeholder('limit'))
  .offset(sql.placeholder('offset'))
  .prepare('get_pois_with_count')

export async function getPromotedPois({
  pos: [longitude, latitude],
  distanceKm,
  themeName,
  filtersOpts,
  filterDate,
  offset,
  pageSize,
}: {
  // do not use tagged type because of telefunc shields
  pos: [longitude: number, latitude: number]
  distanceKm: number
  // do not use tagged type because of telefunc shields
  themeName: string | null
  filtersOpts: HomeOptFilter[]
  // do not use tagged type because of telefunc shields
  filterDate: [string, string] | [string] | null
  offset: number
  pageSize: number
}): Promise<Array<GetPoisResponseItem & { theme: ThemeName | null }>> {
  const { lang, logger } = getContext()
  try {
    const [filterStartDate, filterEndDate] = parseDateRange(
      filterDate?.[0],
      filterDate?.[1],
      logger,
    )
    const distance = clamp(distanceKm, 0, MAX_DISTANCE_KM) * 1000
    const limit = clamp(pageSize, 0, MAX_PAGE_SIZE)

    const values = {
      longitude,
      latitude,
      distance,
      lang,
      filterStartDate,
      filterEndDate,
      filterPmr: filtersOpts.includes('pmr'),
      filterPetsAllowed: filtersOpts.includes('pets-allowed'),
      filterKidsFriendly: filtersOpts.includes('kids-friendly'),
      filterOutside: filtersOpts.includes('outside'),
      filterFree: filtersOpts.includes('free'),
      theme: themeName,
      limit,
      offset,
    }
    // console.log('getPromotedPois values => ', values)
    const result = await preparedGetPromotedPois.execute(values)

    return result
  } catch (err) {
    logger.error(err, 'getPromotedPois error')
    throw new Error('unexpected-error')
  }
}

const poiThemeScore = db.$with('poi_theme_score').as((qb) => {
  return qb
    .select({
      theme: sql<ThemeName>`${schema.poisToThemes.themeName}`.as('theme'),
      score: sql<number>`SUM(CASE ${
        schema.userTracking.event
      } WHEN 'add_fav_poi' THEN 3 WHEN 'remove_fav_poi' THEN -3 WHEN 'view_detail_poi' THEN 1 ELSE 0 END)`.as(
        'score',
      ),
    })
    .from(schema.userTracking)
    .innerJoin(
      schema.poisToThemes,
      eq(schema.userTracking.poiId, schema.poisToThemes.poiId),
    )
    .where(
      and(
        eq(schema.userTracking.userId, sql.placeholder('userId')),
        inArray(schema.userTracking.event, [...TRACKING_EVENTS_POI]),
      ),
    )
    .groupBy(schema.poisToThemes.themeName)
})

const themeScore = db.$with('theme_score').as((qb) => {
  return qb
    .select({
      theme: sql<ThemeName>`${schema.userTracking.themePayload}`.as('theme'),
      score: sql<number>`SUM( CASE ${
        schema.userTracking.event
      } WHEN 'add_fav_theme' THEN 100 WHEN 'remove_fav_theme' THEN -100 WHEN 'view_detail_theme' THEN 0 ELSE 0 END )`.as(
        'score',
      ),
    })
    .from(schema.userTracking)
    .where(
      and(
        eq(schema.userTracking.userId, sql.placeholder('userId')),
        inArray(schema.userTracking.event, [...TRACKING_EVENTS_THEME]),
      ),
    )
    .groupBy(schema.userTracking.themePayload)
})

const combinedScores = db
  .with(poiThemeScore, themeScore)
  .select({
    theme: sql<ThemeName>`combined.theme`.as('theme'),
    totalScore: sql<number>`SUM(combined.score)`.as('totalScore'),
  })
  .from(
    sql`(SELECT * FROM ${poiThemeScore} UNION ALL SELECT * FROM ${
      themeScore
    }) combined`,
  )
  .groupBy(sql`theme`)
  .prepare('combined_scores')

function parseDateRange(
  start: string | undefined,
  end: string | undefined,
  logger: Logger,
): [DateString, DateString] | [null, null] {
  try {
    if (typeof start !== 'undefined') {
      assertDateString(start)
    }
    if (typeof end !== 'undefined') {
      assertDateString(end)
    }

    if (start && end) {
      return [start, end]
    }
    if (start) {
      return [start, start]
    }
    return [null, null]
  } catch (e) {
    logger.error(e, 'parseDateRange error')
    return [null, null]
  }
}

export async function getRecommendedPois({
  pos: [longitude, latitude],
  distanceKm,
  filtersOpts,
  filterDate,
  offset,
  pageSize,
}: {
  // do not use tagged type because of telefunc shields
  pos: [longitude: number, latitude: number]
  distanceKm: number
  filtersOpts: HomeOptFilter[]
  // do not use tagged type because of telefunc shields
  filterDate: [string, string] | [string] | null
  offset: number
  pageSize: number
}): Promise<Array<GetPoisResponseItem & { theme: ThemeName | null }>> {
  const { userId, logger, lang } = getContext()

  if (userId == null) {
    return []
  }

  const [filterStartDate, filterEndDate] = parseDateRange(
    filterDate?.[0],
    filterDate?.[1],
    logger,
  )
  const distance = clamp(distanceKm, 0, MAX_DISTANCE_KM) * 1000
  const limit = clamp(pageSize, 0, MAX_PAGE_SIZE)

  const values = {
    longitude,
    latitude,
    distance,
    lang,
    filterStartDate,
    filterEndDate,
    filterPmr: filtersOpts.includes('pmr'),
    filterPetsAllowed: filtersOpts.includes('pets-allowed'),
    filterKidsFriendly: filtersOpts.includes('kids-friendly'),
    filterOutside: filtersOpts.includes('outside'),
    filterFree: filtersOpts.includes('free'),
    limit: Math.floor(limit / 3),
    offset,
    excludePoiId: null,
  }

  const scores = await combinedScores.execute({
    userId,
  })

  const [score_1, score_2, score_3, score_4, score_5]: Array<
    { theme: ThemeName; totalScore: number } | undefined
  > = scores.toSorted((a, b) => {
    return b.totalScore - a.totalScore
  })

  const results = (
    await Promise.all(
      [score_1, score_2, score_3, score_4, score_5]
        .filter(Boolean)
        .map(async (score) =>
          preparedGetPoisWithCount
            .execute({ ...values, theme: score.theme })
            .then((result) => {
              return result.map((poi) => {
                return {
                  ...poi,
                  theme: score.theme,
                }
              })
            }),
        ),
    )
  )
    .flat()
    .sort((a, b) => {
      if (
        (a.startDate != null || b.startDate != null) &&
        a.startDate !== b.startDate
      ) {
        return isAfter(a.startDate ?? '9999-12-31', b.startDate ?? '9999-12-31')
          ? 1
          : -1
      }
      if (
        a.startDate === b.startDate &&
        a.poiTime != null &&
        b.poiTime != null
      ) {
        return isAfter(a.poiTime, b.poiTime) ? -1 : 1
      }
      return a.distance - b.distance
    })

  return results.slice(0, limit)
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function warmUp(): Promise<void> {
  const { logger } = getContext()
  logger.info('warming up home.telefunc...')
}
