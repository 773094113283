import { sql } from 'drizzle-orm'
import { validate } from 'maxmind'
import { getContext } from 'telefunc'

import { type Latitude, type Longitude } from '#db/schema.constants'

import { db } from './db'
import { ipLookup } from './ipLookUp'
import { mapboxGeocode } from './mapbox'

export async function getLocalityByCoordinates(
  // do not use tagged type because of telefunc shields
  [longitude, latitude]: [number, number],
): Promise<string> {
  const result = await mapboxGeocode
    .reverseGeocode({
      longitude,
      latitude,
    })
    .send()

  const placeFeat = result.body.features.find(
    (f) => f.properties.feature_type === 'place',
  )

  const localityName =
    placeFeat?.properties.name_preferred ??
    placeFeat?.properties.name ??
    result.body.features.find((f) => f.properties.feature_type === 'postcode')
      ?.properties.place_formatted ??
    result.body.features.find((f) => f.properties.feature_type === 'address')
      ?.properties.place_formatted ??
    result.body.features.find((f) => f.properties.feature_type === 'street')
      ?.properties.place_formatted ??
    'À coté de vous!'

  return localityName
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function guessPositionByIp(): Promise<{
  coords: [Longitude, Latitude]
  name: string
} | null> {
  const { ip } = getContext()

  if (ip == null) return null
  if (!validate(ip)) return null

  const res = ipLookup.get(ip)

  if (res == null || !('location' in res) || res.location == null) return null

  const { latitude, longitude } = res.location

  const city =
    'city' in res && res.city != null
      ? (res.city.names.fr ?? res.city.names.en)
      : null

  return {
    coords: [longitude as Longitude, latitude as Latitude],
    name: city ?? 'À coté de vous!',
  }
}

export async function searchLocalityByName(name: string): Promise<
  Array<{
    id: string
    name: string
    fullName: string
    position: [Longitude, Latitude]
  }>
> {
  const ret = await mapboxGeocode
    .forwardGeocode({
      query: name,
      countries: ['fr'],
      limit: 5,
      types: ['place'],
    })
    .send()

  return ret.body.features.map((f) => {
    const name = f.properties.name_preferred ?? f.properties.name
    return {
      id: f.id,
      name,
      fullName: [name, f.properties.place_formatted].filter(Boolean).join(', '),
      position: [
        f.properties.coordinates.longitude as Longitude,
        f.properties.coordinates.latitude as Latitude,
      ],
    }
  })
}

export type GetRegionBySlugResult = {
  coords: [Longitude, Latitude]
  name: string
} | null

export async function getRegionBySlug(
  slug: string,
): Promise<GetRegionBySlugResult> {
  const region = await db.query.region.findFirst({
    columns: {
      coords: true,
    },
    extras: {
      name: sql<string>`label ->> 'fr'`.as('name'),
    },
    where(f, ops) {
      return ops.and(
        ops.eq(f.slug, slug),
        ops.isNotNull(f.coords),
        ops.isNotNull(sql<string | null>`label ->> 'fr'`),
      )
    },
  })

  return region
    ? {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        coords: region.coords!,
        name: region.name,
      }
    : null
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function warmUp(): Promise<void> {
  const { logger } = getContext()
  logger.info('warming up geoloc.telefunc...')
}
