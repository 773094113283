import { A, useLocation, useParams } from '@solidjs/router'
import { type JSX, Show } from 'solid-js'

import {
  ChevronDownIcon,
  HeartIcon,
  LightningIcon,
  MenuIcon,
  // SearchMdIcon,
  UserIcon,
} from '#/components/Icon'
import { buttonVariants } from '#components/Button.jsx'

import { useAppStoresCtx } from '../appStores'
import { useI18n } from '../i18n'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './DropdownMenu'
import logoUrl from './logo.webp'

export function Header(): JSX.Element {
  const t = useI18n()
  const { userInfo } = useAppStoresCtx()

  // const searchPlaceholder = (): string => {
  //   return isSmallScreen()
  //     ? t('header.search_placeholder_short')
  //     : t('header.search_placeholder')
  // }
  const params = useParams<{ townSlug?: string }>()
  const location = useLocation()

  void userInfo.refetch()

  return (
    <header class="flex h-24 items-center justify-between gap-6 py-2">
      <div class="flex w-full items-center justify-between gap-6 lg:justify-start">
        <A
          href={`${params.townSlug != null ? `/ville/${params.townSlug}` : '/'}${location.search}`}
          class="w-32 min-w-24"
        >
          <img src={logoUrl} alt="Logo" />
        </A>
        {/* <div class="relative block h-11 items-center justify-start gap-6 text-grey-300">
          <SearchMdIcon class="absolute left-4 top-[calc(50%-0.8rem)] h-6 w-6" />
          <input
            type="text"
            class="placeholder-shown::text-ellipsis w-full rounded-md bg-dark-20 py-2 pl-12 pr-4 font-normal text-base"
            placeholder={searchPlaceholder()}
          />
        </div> */}
        <button class="hidden shrink-0 py-2 sm:block lg:hidden">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <MenuIcon class="size-8" />
            </DropdownMenuTrigger>
            <DropdownMenuContent class="hidden flex-col gap-2 sm:block lg:hidden">
              <A
                href="https://lokaly.fr/createurs-devenements/"
                target="_blank"
                class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
              >
                <LightningIcon class="size-4" />
                {t('header.publish_your_event')}
              </A>
              <Show
                when={userInfo.data}
                fallback={
                  <A
                    href="/auth/login"
                    class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 font-semibold hover:bg-grey-100"
                  >
                    <UserIcon class="size-4" />
                    {t('header.sign_in')}
                  </A>
                }
              >
                {(info) => (
                  <>
                    <A
                      href={`/favorites${location.search}`}
                      class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
                    >
                      <HeartIcon class="size-4" />
                      {t('header.favorites')}
                    </A>
                    <A
                      href={`/profile${location.search}`}
                      class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
                    >
                      <UserIcon class="size-4" />
                      {info().firstName}
                    </A>
                  </>
                )}
              </Show>
            </DropdownMenuContent>
          </DropdownMenu>
        </button>
      </div>
      <div class="hidden items-center justify-start gap-8 py-2 lg:flex">
        <A
          href="https://lokaly.fr/createurs-devenements/"
          target="_blank"
          class="text-nowrap text-sm hover:underline"
        >
          {t('header.publish_your_event')}
        </A>
        <Show
          when={userInfo.data}
          fallback={
            <A
              class={buttonVariants({ variant: 'outline' })}
              href="/auth/login"
            >
              {t('header.sign_in')}
            </A>
          }
        >
          {(info) => (
            <>
              <A
                href={`/favorites${location.search}`}
                class="text-nowrap text-sm hover:underline"
              >
                <HeartIcon class="mr-2 inline-block size-4 align-middle" />
                {t('header.favorites')}
              </A>
              <A
                href={`/profile${location.search}`}
                class={buttonVariants({ variant: 'solid', size: 'sm' })}
              >
                <UserIcon class="mr-2 inline-block size-6" />
                {info().firstName}
                <ChevronDownIcon class="ml-2 inline-block size-4" />
              </A>
            </>
          )}
        </Show>
      </div>
    </header>
  )
}
