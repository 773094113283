import { type RouteDefinition } from '@solidjs/router'
import { lazy } from 'solid-js'

import { AuthLayout } from './pages/auth/AuthLayout'
import Home from './pages/home/Home'
import { NotFound } from './pages/NotFound'

export const ROUTES = [
  {
    path: ['/', '/ville/:townSlug'],
    component: Home,
    info: {
      feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=L'accueil`,
    },
  },
  {
    path: '/auth',
    component: AuthLayout,
    info: { isolationMode: true },
    children: [
      {
        path: '/login',
        component: lazy(async () => import('./pages/auth/LogIn')),
      },
      {
        path: '/signup',
        component: lazy(async () => import('./pages/auth/SignUp')),
      },
      {
        path: '/email-waitroom/:email',
        component: lazy(async () => import('./pages/auth/EmailWaitroom')),
      },
      {
        path: '/verify-email/',
        component: lazy(async () => import('./pages/auth/VerifyEmail')),
      },
      {
        path: '/welcome',
        component: lazy(async () => import('./pages/auth/Welcome')),
      },
      {
        path: '/forgot-password',
        component: lazy(async () => import('./pages/auth/ForgotPassword')),
      },
      {
        path: '/reset-password',
        component: lazy(async () => import('./pages/auth/ResetPassword')),
      },
      {
        path: '/callback/:provider',
        component: lazy(async () => import('./pages/auth/Callback')),
      },
    ],
  },
  {
    path: [
      '/poi/:poiId',
      '/evenement/:slugFr',
      '/ville/:townSlug/evenement/:slugFr',
    ],
    component: lazy(async () => import('./pages/detail/Detail')),
    info: {
      feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Un+d%C3%A9tail`,
    },
  },
  {
    path: '/profile',
    component: lazy(async () => import('./pages/profile/Profile')),
    info: {
      bgColor: 'blue',
      authRequired: true,
      feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Les+param%C3%A8tres`,
    },
  },
  {
    path: '/favorites',
    component: lazy(async () => import('./pages/favorites/Favorites')),
    info: {
      bgColor: 'pink',
      authRequired: true,
      feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Les+favoris`,
    },
  },
  {
    path: ['/category/:theme', '/ville/:townSlug/category/:theme'],
    component: lazy(async () => import('./pages/category/Category')),
    info: {
      feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Liste+dans+une+cat%C3%A9gorie`,
    },
  },
  {
    path: ['/promotions/:theme?', '/ville/:townSlug/promotions/:theme?'],
    component: lazy(async () => import('./pages/promotions/Promotions')),
  },
  {
    path: '*',
    info: { title: 'Not found' },
    component: NotFound,
  },
] as const satisfies RouteDefinition[]
