import { type ThemeName } from '../../db/schema.constants'
import concert1 from './Concert1.webp'
import concert2 from './Concert2.webp'
import concert3 from './Concert3.webp'
import concert4 from './Concert4.webp'
import concert5 from './Concert5.webp'
import concert6 from './Concert6.webp'
import concert7 from './Concert7.webp'
import concert8 from './Concert8.webp'
import concert9 from './Concert9.webp'
import placeholder1Url from './placeholder_1.webp'
import placeholder2Url from './placeholder_2.webp'
import placeholder3Url from './placeholder_3.webp'
import placeholder4Url from './placeholder_4.webp'
import placeholder5Url from './placeholder_5.webp'
import placeholder6Url from './placeholder_6.webp'
import placeholder7Url from './placeholder_7.webp'
import placeholder8Url from './placeholder_8.webp'
import placeholder9Url from './placeholder_9.webp'
import placeholder10Url from './placeholder_10.webp'
import SonEtLumiere from './SonEtLumiere.webp'

const PLACEHOLDERS = {
  spectacles: null,
  concerts: [
    concert1,
    concert2,
    concert3,
    concert4,
    concert5,
    concert6,
    concert7,
    concert8,
    concert9,
  ],
  degustations: null,
  randonnees: null,
  'promenades-a-pied': null,
  'promenades-a-velo': null,
  'promenades-a-cheval': null,
  tourisme: null,
  famille: null,
  sports: null,
  'evenements-artistiques': null,
  theatre: null,
  musees: null,
  'marches-et-brocantes': null,
  cinema: null,
  conference: null,
  expositions: null,
  fetes: null,
  'son-et-lumiere-feu-d-artifice': [SonEtLumiere],
  OTHER: [
    placeholder1Url,
    placeholder2Url,
    placeholder3Url,
    placeholder4Url,
    placeholder5Url,
    placeholder6Url,
    placeholder7Url,
    placeholder8Url,
    placeholder9Url,
    placeholder10Url,
  ],
} as const satisfies Record<ThemeName | 'OTHER', string[] | null>

export function getPlaceholderUrl(
  theme: ThemeName,
  index = Math.floor(Math.random() * 100),
): string {
  if (PLACEHOLDERS[theme] != null) {
    return PLACEHOLDERS[theme][index % PLACEHOLDERS[theme].length]
  }
  return PLACEHOLDERS.OTHER[index % PLACEHOLDERS.OTHER.length]
}
