import { Slider } from '@kobalte/core/slider'
import { type JSX, Show } from 'solid-js'

import { MenuIcon } from '#/components/Icon'
import { useI18n } from '#/i18n'

import { STEPS_KM } from './home.constants'

export function DistanceSlider(props: {
  value: number
  onChange: (arg0: number) => void
}): JSX.Element {
  const t = useI18n()

  return (
    <Slider
      class="relative flex flex-col pb-2.5 pt-2"
      value={[props.value]}
      onChange={(val) => {
        props.onChange(val[0])
      }}
      minValue={0}
      maxValue={STEPS_KM.length - 1}
      step={1}
    >
      <div class="flex h-8 w-full flex-col justify-center">
        <Slider.Track class="relative h-0.5 w-full bg-grey-300">
          <Slider.Fill class="absolute h-full bg-medium-blue" />
          <Slider.Thumb class="-top-4 flex size-8 cursor-pointer items-center justify-center rounded-full bg-grey-50 outline outline-2">
            <MenuIcon class="h-4 w-4 rotate-90 text-grey-300" />
            <Slider.Input />
            <Show when={props.value > 0 && props.value < STEPS_KM.length - 1}>
              <div class="absolute">
                <div class="relative top-7 flex text-base">
                  {t('city_selector.distance', {
                    distance: STEPS_KM[props.value]?.val,
                  })}
                </div>
              </div>
            </Show>
          </Slider.Thumb>
        </Slider.Track>
      </div>
      <div class="flex justify-between text-grey-600">
        <span>
          {t('city_selector.distance', { distance: STEPS_KM[0].val })}
        </span>
        <span>
          {t('city_selector.distance', {
            distance: STEPS_KM[STEPS_KM.length - 1].val,
          })}
        </span>
      </div>
    </Slider>
  )
}
