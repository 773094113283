import { eq } from 'drizzle-orm'
import { deleteUser as supertokensDeleteUser } from 'supertokens-node'
import { Abort, getContext } from 'telefunc'

import { db, schema } from '#/db'

import {
  type BrevoContactId,
  type Latitude,
  type Longitude,
  type SupertokenId,
  type UserPreferences,
} from '../../db/schema.constants'
import { type AuthSource } from './auth.constants'

export async function getUserProfile(): Promise<
  | {
      email: string
      supertokenId: SupertokenId
      authSource: AuthSource
      firstName: string
      lastName: string
      preferences: UserPreferences | null
      update_events_notification: boolean
      recommendations_notification: boolean
      brevoContactId: BrevoContactId
      position: [Longitude, Latitude] | null
      locality: string | null
    }
  | undefined
> {
  const { userId, logger } = getContext()

  if (userId == null) {
    logger.warn('User not logged in. Skipping getUserProfile.')
    throw Abort()
  }

  try {
    const userProfile = await db.query.userProfile.findFirst({
      where(fields, ops) {
        return ops.eq(fields.supertokenId, userId)
      },
    })

    return userProfile
  } catch (err) {
    logger.error(err, 'getUserProfile error')
    throw new Error('unexpected-error')
  }
}

export async function deleteUser(): Promise<void> {
  const { userId, logger, brevoContactApi } = getContext()

  if (userId == null) {
    logger.warn('User not logged in. Skipping deleteUser.')
    throw Abort()
  }
  try {
    logger.info({ userId }, 'Deleting supertokens user...')
    await supertokensDeleteUser(userId)

    const deletedProfiles = await db
      .delete(schema.userProfile)
      .where(eq(schema.userProfile.supertokenId, userId))
      .returning()

    await Promise.all(
      deletedProfiles.map(async (profile) => {
        await brevoContactApi.deleteContact(profile.brevoContactId)
      }),
    )
  } catch (err) {
    logger.error(err, 'deleteUser error')
    throw new Error('unexpected-error')
  }
}
