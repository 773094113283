import { and, eq, gte, isNull, or, sql } from 'drizzle-orm'
import { Abort, getContext } from 'telefunc'

import { db, schema } from '../../db'
import { jsonTextPlaceholder } from '../../db/jsonb-field'
import {
  type DateString,
  type MediaFileId,
  type PoiId,
  type SupertokenId,
  type ThemeName,
  type TimeString,
} from '../../db/schema.constants'

export type GetFavoritesDetailsResponseItem = {
  id: PoiId
  userId: SupertokenId
  theme: ThemeName | null
  slugFr: string | null
  label: string | null
  mainMediaFileId: MediaFileId | null
  mainMediaFileCredits: string | null
  mainMediaFileLicense: string | null
  startDate: DateString | null
  endDate: DateString | null
  poiTime: TimeString | null
  locality: string | null
}

const preparedGetFavoritesDetails = db
  .select({
    id: schema.usersToFavPois.poiId,
    userId: schema.usersToFavPois.userId,
    slugFr: schema.pointOfInterest.slugFr,
    label: jsonTextPlaceholder(schema.pointOfInterest.label, 'label'),
    mainMediaFileId: sql<MediaFileId | null>`${schema.mediaFile.id}`.as(
      'main_media_file_id',
    ),
    mainMediaFileCredits: sql<string | null>`${schema.mediaFile.credits}`.as(
      'main_media_file_credits',
    ),
    mainMediaFileLicense: sql<string | null>`${schema.mediaFile.license}`.as(
      'main_media_file_license',
    ),
    startDate: schema.event.startDate,
    endDate: schema.event.endDate,
    poiTime: schema.poiExtraTime.poiTime,
    locality: schema.address.locality,
    theme: sql<ThemeName | null>`(SELECT theme_name FROM ${
      schema.poisToThemes
    } WHERE poi_id = ${
      schema.usersToFavPois.poiId
    } ORDER BY theme_name LIMIT 1)`.as('theme'),
  })
  .from(schema.usersToFavPois)
  .leftJoin(
    schema.pointOfInterest,
    eq(schema.usersToFavPois.poiId, schema.pointOfInterest.id),
  )
  .leftJoin(
    schema.address,
    eq(schema.pointOfInterest.addressId, schema.address.id),
  )
  .leftJoin(schema.event, eq(schema.event.poiId, schema.usersToFavPois.poiId))
  .leftJoin(
    schema.poisToMediaFiles,
    and(
      eq(schema.usersToFavPois.poiId, schema.poisToMediaFiles.poiId),
      eq(schema.poisToMediaFiles.isMain, sql`true`),
    ),
  )
  .leftJoin(
    schema.mediaFile,
    eq(schema.poisToMediaFiles.mediaFileId, schema.mediaFile.id),
  )
  .leftJoin(
    schema.poiExtraTime,
    eq(schema.usersToFavPois.poiId, schema.poiExtraTime.poiId),
  )
  .where((fields) => {
    return and(
      eq(fields.userId, sql.placeholder('userId')),
      or(isNull(fields.startDate), gte(fields.startDate, sql`now()::date`)),
    )
  })
  .prepare('get_favorites_details')

const preparedGetFavoritesIds = db.query.usersToFavPois
  .findMany({
    columns: {
      poiId: true,
    },
    where(fields, ops) {
      return ops.eq(fields.userId, sql.placeholder('userId'))
    },
  })
  .prepare('get_favorites_ids')

export async function getFavoritesDetails(): Promise<
  GetFavoritesDetailsResponseItem[]
> {
  const { userId, lang, logger } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    const result = await preparedGetFavoritesDetails.execute({
      userId,
      lang,
    })

    return result
  } catch (err) {
    logger.error(err, 'getFavoritesDetails error')
    throw new Error('unexpected-error')
  }
}

export async function getFavoritesIds(): Promise<PoiId[]> {
  const { userId, logger } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    const result = await preparedGetFavoritesIds.execute({ userId })

    return result.map(({ poiId }) => poiId)
  } catch (err) {
    logger.error(err, 'getFavoritesIds error')
    throw new Error('unexpected-error')
  }
}

export async function addToFavorites(
  // do not use tagged type because of telefunc shields
  poiId: string,
): Promise<void> {
  const { userId, logger, deviceId } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    await db.transaction(async (tx) => {
      await tx.insert(schema.usersToFavPois).values({
        userId,
        poiId: poiId as PoiId,
      })

      await tx.insert(schema.userTracking).values({
        userId,
        deviceId,
        poiId: poiId as PoiId,
        event: 'add_fav_poi',
      })
    })
  } catch (err) {
    logger.error(err, 'addToFavorites error')
    throw new Error('unexpected-error')
  }
}

export async function removeFromFavorites(
  // do not use tagged type because of telefunc shields
  poiId: string,
): Promise<void> {
  const { userId, logger, deviceId } = getContext()
  if (userId == null) {
    throw Abort()
  }
  try {
    await db.transaction(async (tx) => {
      await tx
        .delete(schema.usersToFavPois)
        .where(
          and(
            eq(schema.usersToFavPois.userId, userId),
            eq(schema.usersToFavPois.poiId, poiId as PoiId),
          ),
        )

      await tx.insert(schema.userTracking).values({
        userId,
        deviceId,
        poiId: poiId as PoiId,
        event: 'remove_fav_poi',
      })
    })
  } catch (err) {
    logger.error(err, 'removeFromFavorites error')
    throw new Error('unexpected-error')
  }
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function warmUp(): Promise<void> {
  const { logger } = getContext()
  logger.info('warming up favorites.telefunc...')
}
